import axios from 'axios';
import { useEffect, useState } from 'react';
import Footer from './footer';
import Nav from './navbar';
import React from 'react';
import { Button,Spin ,message} from 'antd';
import {Input } from 'antd';
import { API_URL, footer_menu_class, uploads } from '../config/config';
import Footer_menu from './footer_menu';

const Put_user2 = ()=>{
    // const user_id = localStorage.getItem('token');
    const [messageApi, contextHolder] = message.useMessage();
    const number = localStorage.getItem('number');
    const [product, setProduct] = useState(null);
    const [login,setLogin]= useState();
    const [password, setPassword] = useState();
    const [avatar,setAvatar] = useState(null);
    const [numbers, setNumbers] = useState();
    const [name,setName] = useState();
    const [surname,setSurname] = useState();
    const [city,setCity] = useState();
    const [google_id,setGoogle_id] = useState(null);
    const [uid,setUid] = useState(null);
    const [image_name,setImage_name] = useState(null);
    const [usertoken, setUsertoken] = useState(null);


    const [selectedImage, setSelectedImage] = useState();

    // This function will be triggered when the file field change
    const imageChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedImage(e.target.files[0]);
      }
    };
  
    // This function will be triggered when the "Remove This Image" button is clicked
    const removeSelectedImage = () => {
      setSelectedImage();
    };


    const useridtoken = async () => {
      if(localStorage.getItem('token') != null){
          const post = {
              id: localStorage.getItem('token'),
          };
          const data = await axios({
              method: 'get',
              params: post,
              url: API_URL + '/users5'
          });
          // 
          if (data.data.status == 200) {
              
              fetchProduct(data.data.users5[0].id);
              setUsertoken(data.data.users5[0].id);
          }if(localStorage.getItem('g') != null){
              const post = {
                  id: localStorage.getItem('g'),
              };
              const data = await axios({
                  method: 'get',
                  params: post,
                  url: API_URL + '/users5'
              });
              // 
              if (data.data.status == 200) {
                fetchProduct(data.data.users5[0].id);
                  // 
                  setUsertoken(data.data.users5[0].id);
              }
      }
          } 
    }
    const fetchProduct = async (id) => {
        const post = {
            id: id,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL+'/users1'
        });
        
        if (data.data.status == 200) {
            setProduct(data.data.users1);
            setLogin(data.data.users1[0].login);
            setPassword(data.data.users1[0].password);
            setNumbers(data.data.users1[0].numbers);
            setName(data.data.users1[0].name);
            setSurname(data.data.users1[0].surname);
            setCity(data.data.users1[0].city);
            setImage_name(data.data.users1[0].avatar);
            setGoogle_id(data.data.users1[0].google_id);
            setUid(data.data.users1[0].uid);
        } else {
            

        }   
    }
    // const image_name = product[0].avatar;
    const reg = async ()=>{
      const form = new FormData();
      form.append('login', login);
      form.append('password',password);
      form.append('numbers',numbers);
      form.append('name',name);
      form.append('surname',surname);
      form.append('city',city);
      form.append('user_id',usertoken);
      form.append('uid',uid);
      form.append('google_id',google_id);
      const data = await axios({
          method:'post',
          data:form,
          url:API_URL+'/put_users',
          headers: { "Content-Type": "multipart/form-data" }
      });
      
      if(data != null){
          if(data.data.status  == 200){
          
          
          success2();
          window.location.href='/userinfo/'+1
          // alert('data',data.data.status)
          }else{
             warning();
          }
      }
  }

    const up_avatar = async ()=>{
      
      if(selectedImage != null){
        const form = new FormData();
        form.append('avatar',selectedImage);
        form.append('user_id',usertoken);
        form.append('image_name',image_name);
        const data = await axios({
            method:'post',
            data:form,
            url:API_URL+'/put_avatar',
            headers: { "Content-Type": "multipart/form-data" } 
        });
        
        if(data != null){
            if(data.data.status  == 200){
            spin_success();
            }
        }
      }else{
        error();
      }
        
    }
    
    const success4 = () => {
      messageApi.open({
        type: 'success',
        content: 'Удалено из избранное!',
      });
    };
   
    const spin_success = () => {
      messageApi.open({
        content:  <Spin /> ,
      });
      setTimeout(() => {
        success();
        window.location.href='/put_user2'
      }, 500);
    };
    const success = () => {
      messageApi.open({
        type: 'success',
        content: 'Фото профиля изменено!',
      });
    };
    const success2 = () => {
      messageApi.open({
        type: 'success',
        content: 'Данные профиля изменены!',
      });
    };
    const error = () => {
      messageApi.open({
        type: 'error',
        content: 'Выберите фото!',
      });
    };
    const warning = () => {
      messageApi.open({
        type: 'warning',
        content: 'Вынесите изменение!',
      });
  }
    useEffect(() => {
      useridtoken();
    }, []) 

    const styles = {
      container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 50,
      },
      preview: {
        marginTop: 50,
        display: "flex",
        flexDirection: "column",
      },
      image: { maxWidth: "100%", maxHeight: 320 },
      delete: {
        cursor: "pointer",
        padding: 15,
        background: "red",
        color: "white",
        border: "none",
      },
    };
    return(
        <>
        {contextHolder}
        <Nav/>
        {product != null || product != undefined || product?.length > 0 ?
    <>
        <div class="container card usercard"> 
                <div class="row ">
    <h5>Изменить данные</h5>
                <div className='col-md-3 card'>
                <div className='row'>
                      <div className='col-md-4 '>
                      {selectedImage && (
          <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
             <label class="label2">
             <img
              style={{width:'150px',height:'150px'}}
              src={URL.createObjectURL(selectedImage)}
              // style={styles.image}
              alt="Thumb"
            />
            <button onClick={up_avatar} style={{width:'100%'}} className="btn btn-primary">
              Заменить
            </button>
             </label>
           
          </div>
        )}
              {selectedImage ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
                              <label class="label2">
                              {/* <img style={{width:'150px',height:'150px',borderRadius:'50%'}} src={uploads + product[0].avatar}></img> */}
                              <img  style={{width:'100%',height:'90px'}} src={product[0].avatar != null ? (uploads + product[0].avatar) : (uploads + 'no_image.jpg')} />
                                <input  accept="image/*"
                                onChange={imageChange} type="file"/>
                                <button onClick={up_avatar}> Заменить фото профиля</button>
                              </label>
                          </div>
                </>
              }
                    
                        </div>
                  
                      <div className='col-md-8  mt-3'>
                        <p>{product[0].name}</p>
                        <b style={{color:'gold'}}>Подключить в бизнес аккаунт</b>
                        <p>ID: {product[0].id}</p>                        
                      </div>
                      <div style={styles.container}>
                        
                 

        
      </div>
                      {/* {avatar != null ? <>
                        <img  id="image" style={{width:'100px', height:'100px'}} src={avatar}></img>
                      </>
                      :<></>
                      } */}
                     
                      {/* <div className='col-md-12'>
                        <Button onClick={up_avatar}>Изменить фото профиля</Button>
                    </div>
                     */}
                    </div>
                </div>
                
                <div className='col-md-8 col-12  mt-5'>
                  <div className='row'>
                  <div style={{width:'100%'}} className=' mt-2 card'>
                  <div className='row'>

                  {/* <div  className='col-md-12 col-12'>
                    <p style={{color:'grey',width:'100%'}}>Логин</p>
                     <Input onChange={(e) =>{setLogin(e.target.value)}} allowClear defaultValue={product[0].login} />
                  </div> */}

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Пароль</p>
                    <Input onChange={(e) =>{setPassword(e.target.value)}} allowClear defaultValue={product[0].password} />
                  </div>

                  </div>
                </div>

                <div style={{width:'100%'}} className=' mt-3 card'>
                  <div className='row'>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Имя</p>
                    <Input onChange={(e) =>{setName(e.target.value)}} allowClear defaultValue={product[0].name} />
                  </div>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Фамилия</p>
                    <Input onChange={(e) =>{setSurname(e.target.value)}} allowClear defaultValue={product[0].surname} />
                  </div>

                  </div>
                </div>

                <div style={{width:'100%'}} className='mt-3  card'>
                  <div className='row'>

                  {/* <div className='col-md-12'>
                    <p style={{color:'grey'}}>Номер телефона</p>
                    <Input  onChange={(e) =>{setNumbers(e.target.value)}} allowClear defaultValue={product[0].numbers} />
                  </div> */}

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Город</p>
                    <Input onChange={(e) =>{setCity(e.target.value)}} allowClear defaultValue={product[0].city} />
                  </div>

                  </div>
                </div>

                <div style={{width:'100%'}} className='mt-3  card'>
                  <div className='row'>

                  {/* <div className='col-md-12'>
                    <p style={{color:'grey'}}>E-mail</p>
                    <Input allowClear defaultValue='Не указано' />
                  </div> */}

                  {/* <div className='col-md-12'>
                    <p style={{color:'grey'}}>Пол</p>
                    <Input allowClear defaultValue='Не указано' />
                  </div> */}

                 
                  </div>
                  
                </div>
                <div className='col-md-12 mt-3'>
                        <Button onClick={reg}>Сохранить</Button>
                    </div>
                  </div>
                </div>
                </div>
                </div>
           
</>
    :<>Loading</>
}
<div className='col-md-12 '>
<Footer/>
</div>
<div  className='d-sm-block d-md-none d-lg-none d-xl-none '>
                <Footer_menu />
            </div>
        
    </>
    
    );
    }
    
    
    export default Put_user2;