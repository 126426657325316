import axios from "axios";
import { MediumCircleFilled} from '@ant-design/icons';
import { Button, message, Spin} from 'antd';
import React, { useState } from 'react';
import { Select } from 'antd';
import { Input } from 'antd';
import Nav from "./navbar";
import Footer from "./footer";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URL, footer_menu_class, uploads } from '../config/config';
import Post2 from "./post2";
import Footer_menu from "./footer_menu";


const { TextArea } = Input;

const Odejda_new = () => {
  
  const [custom, setCustom] = useState(true);
  const [custom2, setCustom2] = useState(false);
  const [custom3, setCustom3] = useState(false);
  const [custom4, setCustom4] = useState(false);
  const [custom5, setCustom5] = useState(false);
  const [custom6, setCustom6] = useState(false);
  const [custom7, setCustom7] = useState(false);
  const [custom8, setCustom8] = useState(false);
  const [custom9, setCustom9] = useState(false);
  const [subCategories, setSubcategories] = useState(3);
  const [subCategories2, setSubcategories2] = useState();
  const [subCategories3, setSubcategories3] = useState();
  const [subCategories4, setSubcategories4] = useState();
  const [subCategories5, setSubcategories5] = useState();
  const [subCategories6, setSubcategories6] = useState();
  const [subCategories7, setSubcategories7] = useState();
  const [year, setYear] = useState();
  const [products_year, setProducts_year] = useState();
  const [product, setProduct] = useState();
  const [products_year2, setProducts_year2] = useState();
  const [yearr, setYearr] = useState();
  const userid = localStorage.getItem('token');
  const [square, setSquare] = useState(0);

  const params = useParams();
  const [id, setId] = useState(params.id);

  const [images, setImages] = useState('no_image3.png');
  const [image1, setImage1] = useState('no_image3.png');
  const [image2, setImage2] = useState('no_image3.png');
  const [image3, setImage3] = useState('no_image3.png');
  const [image4, setImage4] = useState('no_image3.png');
  const [image5, setImage5] = useState('no_image3.png');
  const [image6, setImage6] = useState('no_image3.png');
  const [files, setFiles] = useState([]);
  const [mark, setMark] = useState();
  const [name, setName] = useState();
  const [location, setLocation] = useState();
  const [descriptions, setDescriptions] = useState();
  const [categories, setCategories] = useState();
  const [categories2, setCategories2] = useState();
  const [price, setPrice] = useState();
  const [numbers, setNumbers] = useState();
  const [currency, setCurrency] = useState();
  const [podcategories, setPodcategories] = useState(null);
  const [models, setModels] = useState();
  const [buy_sell, setBuy_sell] = useState();
  const [recommend, setRecommend] = useState();
  const [city, setCity] = useState(null);
  const [parent_id,setParent_id] = useState();
  const [generation, setGeneration] = useState();
  const [get_currency, setGet_currency] = useState();
  const [atributes, setAtributes] = useState([]);
  const [serie, setSerie] = useState();
  const [modification, setModification] = useState();
  const [equipment, setEquipment] = useState();
  const [priceot, setPriceot] = useState(0);
  const [pricedo, setPricedo] = useState(1000000);
  const [year_ot, setYear_ot] = useState(0);
  const [year_do, setYear_do] = useState(2024);
  const [currency_value, setCurrency_value] = useState(null);
  const [limit, setLimit] = useState(10);
  const [metro, setMetro] = useState(null);
  const [period, setPeriod] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [floor, setFloor] = useState(null);
  const [acres, setAcres] = useState(0);
  
  const { Option } = Select;

  const limit_add = ()=>{
    fetchProduct();
  }

  // обьявление
  const fetchProduct= async () => {
    const post = {
        id: 723,
        limit: limit
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL + '/post_ad'
    });
    if (data.data.status == 200) {
        setProduct(data.data.post);
        setLimit(limit+16);
        
    } else {
        
        
    }
}
  const [products, setProducts] = useState(null);
  const fetchProducts = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getmetro'
    });
    if (data.data.status == 200) {
      setProducts(data.data.getmetro);
      
    } else {
      
    }
  }
// select
  const [products2, setProducts2] = useState(null);
  const fetchProducts2 = async () => {
    const post = {
      id: 723,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL + '/getcategory'
    });
    if (data.data.status == 200) {
      setCategories(723);
      setProducts2(data.data.getcategory);
      filter();
      
    } else {
      
      
    }
  }
  
  const filter = () => {
    if (products > 0 || products != null) {
      const ad = products.filter(i => i.parent_id == 0);
      
      if (ad.length > 0 && ad[0].city != null) {
        
        return ad[0].city;
      } else {
        return 'error';
      }
    }
  }
// currency 
  const fetchCurrency = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcurrency',
    })
    
    if (data.data.status == 200) {
      setGet_currency(data.data.getcurrency);
    } else {

    }
  }
// podcategories
  const onChange = async (value) => {
    
    setPodcategories(value);
    fetchCategory(value);
    setSubcategories(null);
    setSubcategories2(null);
    setSubcategories3(null);
    setSubcategories4(null);
    setSubcategories5(null);
    setSubcategories6(null);
    setSubcategories7(null);
    setCustom(true);
    setCustom2(false);
    setCustom3(false);
    setCustom4(false);
    setCustom6(false);
    setCustom7(false);
    setCustom8(false);
    setCustom9(false);
 
  };

  const fetchCategory = async (value) => {
      const params = {
        id : value
      }
      const data = await axios({
        method: 'get',
        params : params,
        url: API_URL + '/getcategory',
      });
      
      if (data.data.status == 200){
        setCustom(true);
        setCustom2(false);
        setSubcategories(data.data.getcategory);
      } else{
        setCustom(false);
      }
  }
  
  // period 
  const fetchCategory2 = async (value) => { 
    setCustom2(true);
    const params = {
      id: value
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setCustom2(true);
      setCustom3(false);
      setSubcategories2(data.data.getcategory);
    } else {
      setCustom2(false);
  }
  }
  // rooms 
  const onChange4 = async (value) => {
    
    setCategories2(value);
  };
  const [banner,setBanner] = useState(null);
  const fetchBanner = async () => {
      const data = await axios({
          method:'get',
          url:API_URL+'/reklama'
      });
      if(data.data.status == 200){
          setBanner(data.data.reklama);
          
      }else{
          
      }
  }
  const [messageApi, contextHolder] = message.useMessage();
    const error = () => {
      messageApi.open({
        content:  <Spin /> ,
      });
    };
    const success = () => {
      messageApi.open({
        type: 'success',
        content: 'Успешно!',
      });
    };
    const success2 = () => {
      messageApi.open({
        content:  <Spin /> ,
      });
      setTimeout(success,2000)
    };
    const error2 = () => {
      messageApi.open({
        content:  <Spin /> ,
      });
      setTimeout(warning,2000)
    };
    const warning = () => {
      messageApi.open({
        type: 'warning',
        content: 'Выбирайте категории или заполняйте поле!',
      });
  }
  useEffect(() => {
    fetchProduct();
    fetchProducts();
    fetchProducts2();
    fetchCurrency();
    filter();
    fetchBanner();
  }, [])

  const City = async () => {
    
        const post = {
          categories : categories,
          podcategories : podcategories,
          price_ot : priceot,
          price_do : pricedo,
          currency : currency_value,
          metro : metro,
          buy_sell : buy_sell,
          categories2 : categories2,
          limit : limit
        };
        
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/filter_odejda_new'
        });
        if (data.data.status == 200) {
            setProduct(data.data.post);
            setLimit(limit+10);
            success2();
        }else {
         error2();
        }
    
    }
  return (
    <>
    {contextHolder}
      <Nav />
 
        <div className="row">
          <div className="col-md-2 rek_left">     
          {banner != null ? <>
          {banner.filter(banner => banner.prioritet == 'L1').map(filteredBanner => (
            <a href='/#L1'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads + filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L2').map(filteredBanner => (
            <a href='/#L2'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L3').map(filteredBanner => (
            <a href='/#L3'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L4').map(filteredBanner => (
            <a href='/#L4'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L5').map(filteredBanner => (
            <a href='/#L5'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        </> : <></>}
        {banner != null ? <>
          {banner.filter(banner => banner.prioritet == 'L1').map(filteredBanner => (
            <a href='/#L1'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L2').map(filteredBanner => (
            <a href='/#L2'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L3').map(filteredBanner => (
            <a href='/#L3'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L4').map(filteredBanner => (
            <a href='/#L4'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L5').map(filteredBanner => (
            <a href='/#L5'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        </> : <></>}
          </div>
          <div className="col-md-8">
          <div className="row">
            <div className="col-md-12">
              <h5>Фильтр</h5>
            </div>
          {products2 != null || products2 != undefined || products2?.length > 0 ?
                <>
                    
                        <div className="col-md-auto col-6 mt-3">
                            <Select onChange={onChange} defaultValue={products2[0].name} style={{width:'100%'}}>{products2.map((products2)=>
                                <Option value={products2.id}>{products2.name}</Option>
                                )
                            }
                              </Select> 
                        </div>
                </>
                :<>Loading</>
            }
           
                      
                        {subCategories?.length > 0 ?
                          <>
                          <div className="col-md-3 col-6 mt-3">
                            {/* buysell */}
                            <Select onChange={onChange4} defaultValue={subCategories[0].name} style={{ width: '100%' }}>
                            <Option value={null}>Все </Option>
                              {
                                subCategories.map((products) =>
                                  <Option value={products.id}>{products.name}</Option>
                                )
                              } </Select>
                              </div>
                          </> : <></>
                        }
                        
                        <div className="col-md-3 col-6 mt-3">
                        {products != null || products != undefined || products ?.length > 0 ?<>
                  <Select
                    onChange={setMetro}
                  showSearch
                  style={{
                  width: 200, 
                }}
                
                placeholder="Метро"
                optionFilterProp="children"
                > 
                <Option value={null}>Все</Option>
              {products.map((products) =>
                <Option
                filterOption={(input, products) => (products?.label ?? '').includes(input)}
                filterSort={(productsA, productsB) =>
                  (productsA?.label ?? '').toLowerCase().localeCompare((productsB?.label ?? '').toLowerCase())
                }
                value={products.id_sql}>{products.Station}
                {products.color != 1 ? <> <MediumCircleFilled style={{color : '#' + products.color, fontSize:'22px'}} /> </> :
                                     <>
                                     <img src={products.image} width='15px' height='15px' style={{borderRadius:'50%'}}></img>
                                    </>}
                </Option>
              )
              }
              </Select>
        </>
        :<>Loading</>
        }</div>
       {get_currency ?
            <>
            <div className="col-md-2 col-4 mt-3">
                  <Select onChange={setCurrency_value} defaultValue='Валюта' style={{ width: '100%' }}>
                    {get_currency.map((products) =>
                      <Option value={products.currency}>{products.currency}</Option>
                    )
                    }
                  </Select>
              </div>
            </>:
            <></>  
            }
        <div className="col-md-auto col-8">
            <div className="row">
            <div className="col-md-auto col-6 mt-3">
            <Input onChange={(e) =>{setPriceot(e.target.value != "" ? e.target.value : "0")}} style={{width:'100%', marginLeft:'10px'}} placeholder='Цена от'></Input>
          </div>
          <div className="col-md-auto col-6 mt-3">
          <Input onChange={(e) =>{setPricedo(e.target.value != "" ? e.target.value : "1000000")}} style={{width:'100%'}} placeholder='Цена до'></Input>
          </div>
            </div>
        </div>
              <div className="row mt-2">
              <Button onClick={City}>Показать</Button>
              </div>
         <div className="row ml-1">
         {product != null || product != undefined || product?.length > 0 ?
    <>
    {product.map((item)=>
    <div class="col-6 col-md-3 mt-3">
        <Post2 product={item}/>
    </div>
    
    )
    }
    </>
    :<>Loading</>
}
<div className='col-md-12'>
    <Button onClick={City} type="primary" block>
      Показать Ещё
    </Button>
    </div>
         </div>
          </div>
          </div>
          <div className="col-md-2 rek_right">
          {banner != null ? <>
            {banner.filter(banner => banner.prioritet == 'R1').map(filteredBanner => (
            <a href='/#R1'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R2').map(filteredBanner => (
            <a href='/#R2'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R3').map(filteredBanner => (
            <a href='/#R3'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R4').map(filteredBanner => (
            <a href='/#R4'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R5').map(filteredBanner => (
            <a href='/#R5'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        </> : <></>}
        {banner != null ? <>
            {banner.filter(banner => banner.prioritet == 'R1').map(filteredBanner => (
            <a href='/#R1'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R2').map(filteredBanner => (
            <a href='/#R2'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R3').map(filteredBanner => (
            <a href='/#R3'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R4').map(filteredBanner => (
            <a href='/#R4'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R5').map(filteredBanner => (
            <a href='/#R5'>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></>}</a>
        ))}
        </> : <></>}
          </div>    

      </div>
    
      <Footer />
        <div  className={footer_menu_class}>
 <Footer_menu />
</div>
            
    </>
  )
}
export default Odejda_new;