import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import moment from 'moment-timezone';
import { uploads, API_URL } from '../config/config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { Button, Modal, Radio, Spin, Tabs , Space } from 'antd';
import { message, Upload } from 'antd';
import { AudioOutlined, MediumCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setLikes } from '../redux/actions/like_actions';
import { setProducts } from '../redux/actions/products_actions ';
import { Cloud } from '@mui/icons-material';
import { Box, Chip } from '@mui/joy';


const Post_lk_ak = ({ product }) => {
  const [product3, setProduct3] = useState(null);
  const [products_category, setProducts_category] = useState(null);
  const [products_metro, setProducts_metro] = useState(null);
  const [favorited, setFavorited] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts_category();
    fetchProducts_metro();
    fetchProduct3();
  }, []);

  const fetchProducts_category = async () => {
    try {
      const data = await axios.get(API_URL + '/cat_get');
      if (data.data.status === 200) {
        setProducts_category(data.data.cat_get);
      }
    } catch (error) {
      console.error('Ошибка при получении категорий:', error);
    }
  };

  const fetchProducts_metro = async () => {
    try {
      const data = await axios.get(API_URL + '/getmetro');
      if (data.data.status === 200) {
        setProducts_metro(data.data.getmetro);
      }
    } catch (error) {
      console.error('Ошибка при получении метро:', error);
    }
  };

  const fetchProduct3 = async () => {
    try {
      const data = await axios.get(API_URL + '/users_get');
      if (data.data.status === 200) {
        setProduct3(data.data.users_get);
      }
    } catch (error) {
      console.error('Ошибка при получении пользователей:', error);
    }
  };

      
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const { likes } = useSelector((state) => state.likes);
    const { products } = useSelector((state) => state.products);
    const [product4, setProduct4] = useState(null);
    const [message1, setMessage1] = useState(null);
    const limit2 = localStorage.getItem('limit');
    const [limit, setLimit] = useState(localStorage.getItem('limit'));
    const [logged, setLogged] = useState(false);
    const [ad_id, setAd_id] = useState(null);
    const [user2_id, setUser2_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [usertoken, setUsertoken] = useState(null);
    const [searchItem, setSearchItem] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clicks, setClicks] = useState(false);
    const [products_click, setProducts_click] = useState(null);
    const useridtoken = async () => {
        if(localStorage.getItem('token') != null){
            const post = {
                id: localStorage.getItem('token'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });

            if (data.data.status == 200) {
                
                setUsertoken(data.data.users5[0].id);
                Logged(data.data.users5[0].id);
            }
            
            
            
            
            if(localStorage.getItem('g') != null){
                const post = {
                    id: localStorage.getItem('g'),
                };
                const data = await axios({
                    method: 'get',
                    params: post,
                    url: API_URL + '/users5'
                });

                if (data.data.status == 200) {

                    setUsertoken(data.data.users5[0].id);
                    Logged(data.data.users5[0].id);
                }
        }
            } 
      }
    
    const Logged = (usertoken) => {
        if (usertoken != null) {
            // 
            setUsertoken(usertoken);
            setLogged(true);
        }
    }

const fetchProducts_metro2 = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getmetro'
});
if (data.data.status == 200){
  setProducts_metro(data.data.getmetro);
//   
} else {
  
}
}

const fetchProducts_click = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getclick'
});
// 
if (data.data.status == 200){
  setProducts_click(data.data.getview);
//   
} else {
  
}
}
  
    


const chat_if = async () => {
    if (logged == true) {
        const params = {
            ad_id: ad_id,
            user1_id: usertoken,
            user2_id: user2_id,
        };
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/chat_if'
        });
        
        if (data.data.status == 200){
            chat(data.data.chat_if[0].ad_id,data.data.chat_if[0].id);
        } else {
           error();
            
            chats();
            // window.location.href="/mainsms/";
            
        }
    } else {
       warning();
    }

}
    
    const chats = async ()=>{
        const params = {
            ad_id:ad_id,
            user1_id:usertoken,
            user2_id:user2_id,
        };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/chats'
        });
        
        if(data != null){
            if(data.status  == 200){
            
            // fetchProducts();
            localStorage.setItem('chat',message1);
            setTimeout(chat_if,2000);
            // window.location.href="/mainsms/"+product[0].id;
            }else{
                alert('Error')
            }
        }
    }
    
    const chat = async (ad_id,room_id) => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
                message: message1,
                room_id: room_id,
                user_id2: user2_id
            };
            const data = await axios({
                method: 'post',
                params: params,
                url: API_URL + '/chat'
            });
            
            if (data != null){
                if (data.status == 200){
                    chats_date(room_id);
                    success();
                } else {
                    alert('Error')
                }
            }
        }
    }
    const chats_date = async (room_id)=>{
        const params = {
            room_id:room_id
        };
        const data = await axios({
            method:'put',
            params:params,
            url:API_URL+'/chats'
        });
        
        if(data != null){
            if(data.data.status  == 200){
            
            }else{
                alert('Error')
            }
        }
    }
    const fetchProducts2 = async (usertoken) => {
        if(usertoken != null){
            const post = {
                user_id: usertoken,
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL+'/get_favorites'
            });
            
            if (data.data.status == 200) {
                dispatch(setLikes(data.data.favorites));
                
            } else {
                
                
            }
        }
       
      }


    const addfavorites = async (ad_id) => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
            };
            const data = await axios({
                method: 'post',
                params: params,
                url: API_URL+'/favorites'
            });
            console.log(data);
            if (data != null) {
                if (data.data.status == 200) {
                   
                    
                    setFavorited(true);
                    success3();
                    fetchProducts2(usertoken); 
                } else {
  
                }
            }
        }else{
            warning();
        }
        
    }
                
    const delfavorites = async (ad_id) => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
            };
            
            const data = await axios({
                method: 'delete',
                params: params,
                url: API_URL+'/delete_favorites'
            });
            
            if (data != null) {
                if (data.data.status == 200) {
                    
                    setFavorited(false);
                    success4();
                    fetchProducts2(usertoken);
                    
                } else {
                }
            }
        }
        
    }

    

    const fetchProduct4 = async () => {
        const post = {
            id: product.userid,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL +'/users1'
        });
        // 
        if (data.data.status == 200) {
            setProduct4(data.data.users1);
            // 
        } else {
            
            
        }
    }
    


    const checkLikes = (id) => {
        // 
        const check = likes.filter(i => i.ad_id == id);
        if (check.length > 0) {
            setFavorited(true);
            return true;
        } else {
            setFavorited(false);
            return false;
        }
    }
    if (favorited == null) { 
            checkLikes(product.id);
    }
    
const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Сообщение отправлено!',
    });
  };
  const success3 = () => {
    messageApi.open({
      type: 'success',
      content: 'Добавлено в избранное!',
    });
  };
  const success4 = () => {
    messageApi.open({
      type: 'success',
      content: 'Удалено из избранное!',
    });
  };
 
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Авторизуйтесь!',
    });
}
const iz_status_na2 = async (ad_id)=>{
    const params = {
        status:'2',
        ad_id:ad_id
    };
    const data = await axios({
        method:'post',
        params:params,
        url:API_URL+'/iz_status3'
    });
    // 
    if(data != null){
        if(data.status  == 200){
        // 
        // fetchProducts3(usertoken);
        // Userinfo.reload();
        }else{
            alert('Error')
        }
    }
}



    useEffect(() => {
        useridtoken(); 
        fetchProducts_category(); 
            fetchProducts_click();
            fetchProduct3();
            Logged();
            fetchProduct4();
            fetchProducts_metro2();  
    }, []);

  
    const showModal = (id,user2_id) => {
        setAd_id(id);
        setUser2_id(user2_id);
      setIsModalOpen(true);
      
    };
    // 
    const handleOk = () => {
        chat_if();
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
   
   
     
     

      const handleInputChange = (e) => { 
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
      }
      

     
      const click = async (ad_id) => {
        if (ad_id != null) {
            const params = {
                ad_id: ad_id,
            };
            const data = await axios({
                method: 'get',
                params: params,
                url: API_URL + '/click_post'
            });
            
            if (data.data.status == 200){
                add_click(ad_id);
                // setClicks(data.data.click_post);
                
            } else {
               new_click(ad_id);
            }
        } else {
        }
    }
    const add_click = async (ad_id) => {
        const params = {
            ad_id: ad_id
        };
        const data = await axios({
            method: 'put',
            params: params,
            url: API_URL + '/click_post'
        });
        
        if (data != null){
            if (data.status == 200){
                navigate('/post/'+ad_id);
                // window.location.href='/post/'+ad_id
            } else {
            }
        }
    }
    const new_click = async (ad_id) => {
        const params = {
            ad_id: ad_id,
            click: 1
        };
        const data = await axios({
            method: 'post',
            params: params,
            url: API_URL + '/click_post'
        });
        
        if (data != null){
            if (data.status == 200){
                navigate('/post/'+ad_id);
                // window.location.href='/post/'+ad_id
            } else {
            }
        }
    }
    const fetchProductClicks = async () => {
      try {
          const response = await axios.get(API_URL + '/click_post', {
              params: { ad_id: product.id },
          });
          if (response.data.status === 200) {
              setClicks(response.data.clicks); // Сохраняем количество просмотров в состоянии
          } else {
              console.error('Не удалось получить количество просмотров');
          }
      } catch (error) {
          console.error('Ошибка при получении количества просмотров:', error);
      }
  };
  
   

  const renderMetroInfo = (filteredMetro) => {
    const metroColor = filteredMetro.color !== 1 ? `#${filteredMetro.color}` : 'gray'; // Используем цвет из filteredMetro.color
  
    return (
      <div className="flex items-center text-gray-600 text-sm">
        {filteredMetro.color !== 1 ? (
          <>
            <span className="mr-2 truncate text-xs">{filteredMetro.Station}</span>

            <div 
              className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full flex items-center justify-center text-xs font-bold"
              style={{ backgroundColor: metroColor, color: 'white' }} // Применяем цвет фона и белый цвет для буквы
            >
              M
            </div>
          </>
        ) : (
          <>
            <span className="mr-2 truncate">{filteredMetro.Station}</span>
            <img 
              src={filteredMetro.image} 
              alt="Metro" 
              className="w-5 h-5 rounded-full flex-shrink-0" 
            />
          </>
        )}
      </div>
    );
  };
  
  

  const renderUserAvatar = (filteredAvatar) => (
    <a href={'/user/' + filteredAvatar.id}>
      <img
      src={filteredAvatar.avatar ? uploads + filteredAvatar.avatar : uploads + 'user.jpg'}
      alt="User Avatar"
      className="w-8 h-8 rounded-full object-cover flex-shrink-0"
      />
    </a>
  );

  const formatPrice = (price) => {
    // Проверка на число и форматирование с разделителем тысяч
    const priceNumber = parseFloat(price); // Преобразуем строку в число, если это необходимо
    if (isNaN(priceNumber)) {
      return '—'; // Если цена не число, возвращаем дефолтное значение
    }
    return priceNumber.toLocaleString('ru-RU'); // Используем 'ru-RU' для форматирования с точкой как разделителем
  };
  
  if (!product ) {
    return <div>Загрузка...</div>; // Покажите индикатор загрузки или текст
  }
  const click_href = (ad_id) => {
    navigate('/update_ad/'+ad_id);
    }

  
  return (
    
    <div className="flex flex-col h-full bg-white border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
      <Modal  title="Отправить сообщение" open={isModalOpen} onOk={handleOk} okText='Отправить' cancelText="Отмена" onCancel={handleCancel}>
    <textarea onChange={(e) => { setMessage1(e.target.value) }} style={{height:'100px'}} className="form-control"></textarea>
     </Modal>
     {<div style={{zIndex:'5'}}>{contextHolder}</div>}
     
    
       
      {/* Контейнер изображения */}
      
      <div 
        className="relative h-56 w-full overflow-hidden cursor-pointer"
        
      >
        {/* Знак категории */}
        {products_category && (
          <div className="absolute top-2 left-2 z-1">
            {products_category
              .filter((category) => category.id === product.categories)
              .map((filteredCategory) => (
                filteredCategory.name && (
                  <span
                    key={filteredCategory.id}
                    className="bg-green-600 text-white text-xs px-2 py-1 rounded"
                  >
                    {filteredCategory.name}
                  </span>
                )
              ))}
          </div>
        )}

        {/* Кнопка "Избранное" */}
        
{favorited ?
<>
  <button 
    onClick={() => delfavorites(product.id)} className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
  <i  className={`fa-heart  fa-solid text-red-400 fa-regular text-xl`} />
  </button>
</>
: <>
   <button 
    onClick={() => addfavorites(product.id)} className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
  <i  className={`fa-heart  fa-regular text-xl`} />
  </button>
</>
}



        {/* Изображение продукта */}
        <img
        
          onClick={() => click(product.id)}
          src={product.images ? uploads + product.images : uploads + 'no_image.jpg'}
          alt="Image"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Детали продукта */}
    <div className="flex flex-col flex-grow p-1 ">
      {/* Цена */}
      <div className="flex justify-between items-center mb-0 ">
        <h2 className="text-lg  font-bold text-black roboto mb-0">
          {product.price != 0 
            ? `${formatPrice(product.price)} ${product.currency}` 
            : 'Договорная'}
        </h2>
        {/* {products_click != null ? <>
        {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
      <a>
          {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'3%',fontSize:'10px',zIndex:'1',padding:'1px'}} type="button" classname="btn btn-dark">Посмотрели: {filteredView.click}</button></> :
            <>
          </>}
      </a>
      ))}
      </> : <>
      </>} */}
      </div>

        {/* Описание */}
        <p className="text-gray-700 text-sm mb-0 h-10 overflow-hidden line-clamp-2 font-roboto">
            {product.descriptions}
        </p>


        {/* Информация о метро */}
        <div className="flex justify-between items-center mb-3">
            

            
          <div className="flex-grow overflow-hidden">
            {products_metro &&
              products_metro
                .filter((metro) => metro.id_sql === product.metro)
                .map((filteredMetro) => (
                  <div key={filteredMetro.id_sql}>
                    {renderMetroInfo(filteredMetro)}
                  </div>
                ))}
          </div>
          
          <div className="flex flex-col sm:flex-row items-start sm:items-center">
  <span className="text-xs sm:text-sm md:text-base lg:text-lg text-gray-500 ml-2 flex-shrink-0 block sm:inline">
    {moment.tz(product.date1, 'Europe/Moscow').fromNow()}
  </span>
  {/* Добавьте другие элементы, которые нужно отобразить рядом */}
</div>


        </div>

  
        <div className="mt-auto flex justify-between items-center">
        <button 
        style={{fontSize:'8px'}}
       onClick={()=>click_href(product.id)}
  className="flex items-center justify-center bg-blue-600 text-white hover:bg-green-700 transition-colors duration-200 ease-in-out rounded-full py-2 px-6 font-semibold shadow-lg transform active:scale-95"
  aria-label="Редактировать"
>
<i class="fa-solid fa-pencil text-sm"></i>
</button>

          <button 
          style={{fontSize:'8px'}}
  onClick={()=>iz_status_na2(product.id)}
  className="flex items-center justify-center bg-red-600 text-white hover:bg-green-700 transition-colors duration-200 ease-in-out rounded-full py-2 px-6 font-semibold shadow-lg transform active:scale-95"
  aria-label="Деактивировать"
>
<i class="fa-solid fa-power-off text-sm"></i>
</button>



        </div>
      </div>
    </div>
  );
};

export default Post_lk_ak;