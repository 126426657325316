import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  IconButton, 
  Badge, 
  Avatar, 
  Tooltip,
  Menu,
  MenuItem
} from '@mui/material';
import { 
  Mail as MailIcon, 
  Favorite as FavoriteIcon, 
  ExitToApp, 
  Add, 
  Search as SearchIcon,
  Home as HomeIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config/config';

const Nav2 = () => {
  const [logged, setLogged] = useState(false);
  const [status, setStatus] = useState(false);
  const [usertoken, setUsertoken] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      const g = localStorage.getItem('g');
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/users5`, { params: { id: token } });
          if (response.data.status === 200) {
            setLogged(true);
            setUsertoken(response.data.users5[0].id);
          }
        } catch (error) {
          console.error('Ошибка проверки токена:', error);
          localStorage.clear();
          setLogged(false);
        }
      }
      if (g) {
        try {
          const response = await axios.get(`${API_URL}/users5`, { params: { id: g } });
          if (response.data.status === 200) {
            setLogged(true);
            setUsertoken(response.data.users5[0].id);
          }
        } catch (error) {
          console.error('Ошибка проверки токена:', error);
          localStorage.clear();
          setLogged(false);
        }
      }
    };
    checkAuth();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setLogged(false);
    navigate('/');
    handleCloseMenu();
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar 
     
      position="sticky" 
      color="default" 
      elevation={1}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(10px)',
        borderBottom: '1px solid rgba(0,0,0,0.1)'
      }}
    >
      <Toolbar sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        maxWidth: 'lg',
        margin: '0 auto',
        width: '100%',
        padding: theme => theme.spacing(0, 2)
      }}>
        {/* Логотип */}
        <Typography 
          
          sx={{ 
            fontWeight: 'bold',
            fontSize:'36px',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'Cinzel, serif '}}>
            Birimdik
          </Link>
        </Typography>

      
        <div style={{ display: 'flex', alignItems: 'center'}}>
          {logged ? (
            <>
              <Tooltip title="Главная">
                <IconButton color="primary" component={Link} to="/">
                  <HomeIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Добавить">
                <IconButton color="primary" component={Link} to="/upload1/0">
                  <Add />
                </IconButton>
              </Tooltip>

              <Tooltip title="Сообщения">
              <IconButton color="primary" component={Link} to={`/mainsms/${usertoken}`}>
                  <Badge color="error" variant={status ? 'dot' : ''}>
                    <MailIcon />
                  </Badge>
                </IconButton>
              </Tooltip>

              <Tooltip title="Избранное">
                <IconButton color="primary" component={Link} to="/korzina">
                  <FavoriteIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Профиль">
                <IconButton 
                  color="primary" 
                  onClick={handleOpenMenu}
                >
                  <Avatar 
                    sx={{ 
                      width: 32, 
                      height: 32, 
                      bgcolor: 'primary.main' 
                    }}
                  >
                    <PersonIcon />
                  </Avatar>
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => navigate(`/userinfo/${usertoken}`)}>
                  <PersonIcon sx={{ mr: 1 }} /> Профиль
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ExitToApp sx={{ mr: 1 }} /> Выход
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button 
                variant="outlined" 
                color="primary" 
                component={Link} 
                to="/vhod"
                sx={{ 
                  mr: 1,
                  textTransform: 'none',
                  borderRadius: '24px'
                }}
              >
                Вход
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => navigate('/registr')}
                sx={{ 
                  borderRadius: '24px',
                  textTransform: 'none'
                }}
              >
                Регистрация
              </Button>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Nav2;













// import React,{ Component } from 'react';
// import { Button, Modal, Radio, Select, Tabs } from 'antd';
// import  {useState} from 'react'
// import { AudioOutlined } from '@ant-design/icons';
// import { Input, Space  } from 'antd';
// import { useEffect } from 'react';
// import { API_URL } from '../config/config';
// import axios from "axios";
// import { Option } from 'antd/lib/mentions';

// const { Search } = Input;
// const suffix = (
//   <AudioOutlined
//     style={{
//       fontSize: 16,
//       color: '#1890ff',
//     }}
//   />
// );
// const onSearch = (value) => console.log(value);
// // const user_id = localStorage.getItem('token');
// // const room = localStorage.getItem('room');
// const Nav2 =()=>{
//   const [usertoken, setUsertoken] = useState(null);
//   const useridtoken = async () => {
//     if(localStorage.getItem('token') != null){
//         const post = {
//             id: localStorage.getItem('token'),
//         };
//         const data = await axios({
//             method: 'get',
//             params: post,
//             url: API_URL + '/users5'
//         });
        
//         if (data.data.status == 200) {
            
//             setUsertoken(data.data.users5[0].id);
//             sms1(data.data.users5[0].id);
//         }if(localStorage.getItem('g') != null){
//             const post = {
//                 id: localStorage.getItem('g'),
//             };
//             const data = await axios({
//                 method: 'get',
//                 params: post,
//                 url: API_URL + '/users5'
//             });
            
//             if (data.data.status == 200) {
                
//                 setUsertoken(data.data.users5[0].id);
//                 sms1(data.data.users5[0].id);
//             }
//     }
//         } 
//   }
//   const [products2,setProducts2] = useState(null);
//   const [status, setStatus] = useState(false);
 
 
//   const sms1 = async (user_id) => {
//     const params = {
//         id: user_id,
//         // room: room,
//         // user_id2: user_id2
//     };
//     const data = await axios({
//         method: 'get',
//         params: params,
//         url: API_URL + '/chat_s'
//     });
    
//     if (data != null) {
//         if (data.data.status == 200) {
//             setStatus(true);
//         } else {
//             setStatus(false);
//         }
//     }
// }
  
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () =>{
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };
//   const [logged,setLogged]=useState(false);
//   const local = localStorage.getItem('token');
//  const Logged = ()=>{
//   if(local != null){
//     setLogged(true);
//   }
//  }

//  const fetchProducts2 = async () => {
//   const data = await axios({
//     method: 'get',
//     url: API_URL + '/getmetro'
//   });
//   if (data.data.status == 200) {
//     setProducts2(data.data.getmetro);
    
//   } else {
    
//   }
// }

// const LogOut = ()=>{
//   localStorage.removeItem('token');
//   localStorage.removeItem('token2');
//   localStorage.removeItem('room');
//   localStorage.removeItem('ad_id');
//   localStorage.removeItem('login');
//   localStorage.removeItem('number');
//   localStorage.removeItem('uid');
//   localStorage.removeItem('g');
//   localStorage.removeItem('chat');
//   localStorage.removeItem('text');
//   localStorage.removeItem('limit');
//   localStorage.removeItem('a');
//   setLogged(false);
//   window.location.href="/";
// }
// useEffect(()=>{
//   useridtoken();
//   fetchProducts2();
//   Logged();
//   sms1();
// },[])
// return(
  
//     <>
//     <div className='row'>
//                   <div style={{minHeight:'100px',backgroundColor:'#1E90FF',width:'100%',marginLeft:'0.5px'}}  uk-sticky="position: top ; offset: 0" className='row'>
//                   <div className='col-md-auto col-6'>
//                     <a  href="/"><b style={{fontSize:'28px'}} class="market ">Birimdik</b></a>
//                   </div>
//                     <div className='col-md-2 col-6'>
//                     <ul class="navbar-nav ml-auto text-uppercase f1 mt-3">
//                     <li style={{color:'white'}}>
//                       {logged ?<><a className='podat' style={{color:'white'}} href={'/upload1/'+ 0}> Подать обьявление </a></>:<><a style={{color:'white'}} href='/vhod'>Подать обьявление</a></>}
//                     </li>
//                     </ul>
//                     </div>

//                     <div  className='col-md-1 col-3 mt-3'>
//                     <ul  class="navbar-nav ml-auto text-uppercase f1">
//                     <li>
//                       {logged ?<><a className='navmobtext' style={{color:'white'}} onClick={LogOut} href=''><Button onClick={LogOut}  ghost   type="solid " ><a className='navmobtext' >Выход</a> </Button></a></>:<><a href='/vhod' ><Button  ghost   type="solid " >   <a className='navmobtext' href='/vhod'>Вход</a> </Button></a></>}
//                     </li>
//                     </ul>
//                     </div>
//                     <div className='col-md-2 col-4 mt-3'>
//                     <ul class="navbar-nav ml-auto text-uppercase f1">
//                     <li>
//                       {logged ?<><a className='navmobtext' style={{color:'white'}} href={'/userinfo/'+3}><Button   ghost   type="solid " >   <a style={{color:'white'}} className='navmobtext' href={'/userinfo/'+3}>Личный кабинет</a> </Button></a></>:<><a href='/vhod'><Button   ghost   type="solid " >   <a style={{color:'white'}} href='registr'>Регистрация</a> </Button></a></>}
//                     </li>
//                     </ul>
//                     </div>
//                     <div className='col-12 d-none d-md-block'>
//               <a href={'/mainsms/' + usertoken} ><i  class="fa-solid fa-envelope sms1"></i></a>
//                   {status ? <>
//                     <i style={{color:'red',fontSize:'18px',marginLeft:'5px',position:'absolute',marginTop:'-5px'}} class="fas fa-sms"></i>
//                   </>
//                 :
//                 <></>  
//                 }
//                  <a className='ml-4' href='/korzina'><i class="fa-solid fa-heart heart1 "></i> </a>
//               </div>
//                     <div className='col-5 mt-2 d-block d-md-none'>
//               <a href={'/mainsms/' + usertoken} ><i  class="fa-solid fa-envelope sms1"></i></a>
//                   {status ? <>
//                     <i style={{color:'red',fontSize:'18px',marginLeft:'5px',position:'absolute',marginTop:'-5px'}} class="fas fa-sms"></i>
//                   </>
//                 :
//                 <></>  
//                 }
//                  {logged ?<><a className='ml-4' href={'/korzina/'}><i class="fa-solid fa-heart heart1 "></i> </a></>:<><a className='ml-4' href={'/vhod'}><i class="fa-solid fa-heart heart1 "></i> </a></>}
//               </div>
//                     {/* <div  className='col-md-auto col-2 mt-3'>
//                     <ul style={{float:'left'}} class="navbar-nav ml-auto text-uppercase f1">
//                     <li>
//                       {logged ?<><a  className='navmobtext' href='/userinfo'><Button   ghost   type="solid " >   <a style={{color:'white'}} className='navmobtext' href='/userinfo'>Язык</a> </Button></a></>:<><a href='/userinfo'><Button   ghost   type="solid " >   <a style={{color:'white'}} href='/userinfo'>Язык</a> </Button></a></>}
//                     </li>
//                     </ul>
//                     </div> */}

                    
//                   </div>
//                 </div>



               

                
                 



             


    
    


//       <div style={{backgroundColor:'#2d2d7f'}} class="fix d-none" >
//         <div class="row birimdikdiv2">
          
//             <div style={{height:'100%'}} className='col-md-3 col-6 birimdikdiv '>
            
//                   <a href="/"><b class="market ">Birimdik</b></a>
//             </div>
            
//             {logged ? <>
//             <div  className='col-6 d-sm-block d-md-none'>
//               <a  style={{float:'right',marginTop:'15px',marginRight:'10px'}} onClick={LogOut} href=''><Button onClick={LogOut}  ghost   type="solid " >   <a style={{color:'white'}} >Выход</a> </Button></a>
//             </div>
//                   </>
//                   :
//                   <>
//                     <div className='reg col-6'><Button className='reg' href='/vhod' ghost style={{float:'right',marginTop:'10px'}}>Войти / Регистрация</Button></div>
//                   </>}
//             <div className='col-md-3 d-none d-xl-none d search2'>
//                   <Search
//                   placeholder="Я ищу..."
//                   onSearch={onSearch}
//                   style={{
//                     width: '100%',
//                     borderRadius:'25px'
//                   }}
//                 />
//                   </div>

//                 <div style={{backgroundColor:'#2d2d7f',width:'100%'}} className='col-md-12'>
//                 <a href={'/mainsms/' + usertoken} ><i  class="fa-solid fa-envelope sms1"></i></a>
//                 </div>



//                   <div className='row d-none'>
//                   <div  style={{backgroundColor:'#2d2d7f',width:'100%'}}  className='col-md-9 col-sm-12 '>
//               <div   className='row'>
//                   <nav class="navbar navbar-expand-lg main-nav px-0">
//                 <div className='col-6 col-md-auto'>
//                   <a href={'/mainsms/' + usertoken} ><i  class="fa-solid fa-envelope sms1"></i></a>
//                   {status ? <>
//                     <i style={{color:'red',fontSize:'18px',marginLeft:'5px',position:'absolute',marginTop:'-5px'}} class="fas fa-sms"></i>
//                   </>
//                 :
//                 <></>  
//                 }
                  
//                   <a className='ml-4' href='/korzina'><i class="fa-solid fa-heart heart1 "></i> </a>
//                 </div>
//                 {/* <div className='col-6 reg'><label style={{color:'white',float:'right',marginTop:'-25px'}}>Станция метро: <i class="fas fa-map-marked-alt"></i></label></div> */}
                
//                 {products2 != null || products2 != undefined || products2?.length > 0 ?
//                 <>
//                   <div style={{marginTop:'-20px',float:'right'}} className="col-md-3 col-6 d-sm-block d-md-none">
//                     {/* auto */}
//                     <Select   defaultValue={'Выберите метро'} style={{ width: '100%',fontSize:'12px',float:'right'}}>
//                       {products2.map((products) =>
//                         <Option style={{ width: '100%',fontSize:'12px'}} value={products.id}>{products.name}</Option>
//                       )
//                       }
//                     </Select>
//                   </div>
//                   </>
//                 : <>Loading</>
//               }
              
              
//       </nav>
               
//               </div>
              
//             </div>
//                   </div>
            
           
          
          

           
//          </div>
//       </div>

      
      

    
//     </>
    
// )
// }


// export default Nav2;


