import React, { useState } from "react";
import { PDFDocument, rgb } from "pdf-lib";
import { saveAs } from "file-saver";

const Pdf = () => {
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    address: "",
  });

  // Обработка изменения полей формы
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Генерация PDF
  const generatePDF = async () => {
    // Загрузка шаблона PDF
    const templateUrl = "/template.pdf"; // путь к шаблону PDF
    const templateBytes = await fetch(templateUrl).then((res) => res.arrayBuffer());

    // Создание PDF-документа
    const pdfDoc = await PDFDocument.load(templateBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Добавление текста на PDF
    firstPage.drawText(formData.name, { x: 100, y: 500, size: 12, color: rgb(0, 0, 0) });
    firstPage.drawText(formData.age, { x: 100, y: 480, size: 12, color: rgb(0, 0, 0) });
    firstPage.drawText(formData.address, { x: 100, y: 460, size: 12, color: rgb(0, 0, 0) });

    // Сохранение PDF
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(blob, "filled_template.pdf");
  };

  return (
    <div>
      <h1>Заполните форму</h1>
      <form>
        <label>
          Имя:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Возраст:
          <input
            type="text"
            name="age"
            value={formData.age}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Адрес:
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </label>
        <br />
        <button type="button" onClick={generatePDF}>
          Скачать PDF
        </button>
      </form>
    </div>
  );
};

export default Pdf;
