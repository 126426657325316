import axios from "axios";
import {MediumCircleFilled} from '@ant-design/icons';
import {message, Spin} from 'antd';
import React, { useState } from 'react';
import { Select } from 'antd';
import Nav from "./navbar";
import Footer from "./footer";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URL, footer_menu_class, uploads } from '../config/config';
import Footer_menu from "./footer_menu";
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import FormControl from '@mui/joy/FormControl';
import { styled } from '@mui/joy/styles';
import Input from '@mui/joy/Input';
import InputEmojiWithRef from "react-input-emoji";

const TextMaskAdapter = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7 000 000 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const TextMaskAdapter2 = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+996 000 00 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const StyledInput = styled('input')({
  border: 'none', // remove the native input border
  minWidth: 0, // remove the native input width
  outline: 0, // remove the native input outline
  padding: 0, // remove the native input padding
  paddingTop: '1em',
  flex: 1,
  color: 'inherit',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  textOverflow: 'ellipsis',
  '&::placeholder': {
    opacity: 0,
    transition: '0.1s ease-out',
  },
  '&:focus::placeholder': {
    opacity: 1,
  },
  '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
    top: '0.5rem',
    fontSize: '0.75rem',
  },
  '&:focus ~ label': {
    color: 'var(--Input-focusedHighlight)',
  },
  '&:-webkit-autofill': {
    alignSelf: 'stretch', // to fill the height of the root slot
  },
  '&:-webkit-autofill:not(* + &)': {
    marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
    paddingInlineStart: 'var(--Input-paddingInline)',
    borderTopLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    borderBottomLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
  },
});
const StyledLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  lineHeight: 1,
  top: 'calc((var(--Input-minHeight) - 1em) / 2)',
  color: theme.vars.palette.text.tertiary,
  fontWeight: theme.vars.fontWeight.md,
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));
const InnerInput = React.forwardRef(function InnerInput(props, ref) {
  const id = React.useId();
  return (
    <React.Fragment>
      <StyledInput {...props} ref={ref} id={id} />
      <StyledLabel htmlFor={id}>Label</StyledLabel>
    </React.Fragment>
  );
});

const onChange = (value) => {
  
};
const { TextArea } = Input;

const Doma = () => {

  TextMaskAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  const [country, setCountry] = useState('1');
  const onChange0 = (value) => {
    setCountry(value);
    setNumbers(null);
  };
  const params = useParams();
  const [id, setId] = useState(params.id);

  // const userid = localStorage.getItem('token');
  const [location, setLocation] = useState();
  const [descriptions, setDescriptions] = useState();
  const [categories, setCategories] = useState(1);
  const [categories2, setCategories2] = useState(null);
  const [images, setImages] = useState('no_image3.png');
  const [price, setPrice] = useState(0);
  const [numbers, setNumbers] = useState();
  const [currency, setCurrency] = useState("RUB");
  const [custom, setCustom] = useState(false);
  const [custom2, setCustom2] = useState(false);
  const [custom3, setCustom3] = useState(false);
  const [custom4, setCustom4] = useState(false);
  const [custom5, setCustom5] = useState(false);
  const [custom6, setCustom6] = useState(false);
  const [custom7, setCustom7] = useState(false);
  const [custom8, setCustom8] = useState(false);
  const [custom9, setCustom9] = useState(false);
  const [atributes, setAtributes] = useState([]);
  const [subCategories, setSubcategories] = useState();
  const [subCategories2, setSubcategories2] = useState(26);
  const [subCategories3, setSubcategories3] = useState();
  const [subCategories4, setSubcategories4] = useState();
  const [subCategories5, setSubcategories5] = useState();
  const [subCategories6, setSubcategories6] = useState();
  const [subCategories7, setSubcategories7] = useState();
  const [podcategories, setPodcategories] = useState(26);
  const [period, setPeriod] = useState();
  const [floor, setFloor] = useState();
  const [square, setSquare] = useState();
  const [models, setModels] = useState();
  const [files, setFiles] = useState([]);
  const [buy_sell, setBuy_sell] = useState();
  const [image1, setImage1] = useState('no_image3.png');
  const [image2, setImage2] = useState('no_image3.png');
  const [image3, setImage3] = useState('no_image3.png');
  const [image4, setImage4] = useState('no_image3.png');
  const [image5, setImage5] = useState('no_image3.png');
  const [image6, setImage6] = useState('no_image3.png');
  const [recommend, setRecommend] = useState();
  const [city, setCity] = useState();
  const [rooms, setRooms] = useState();
  const [acres, setAcres] = useState();
  const [get_currency, setGet_currency] = useState();
  const [names, setNames] = useState(['']);
  const [products2, setProducts2] = useState([111]);
  const [metro, setMetro] = useState();
  const [useruid, setUseruid] = useState(localStorage.getItem('token'));
  const [usertoken, setUsertoken] = useState(localStorage.getItem('token2'));
  const useridtoken = async () => {
    if(localStorage.getItem('token') != null){
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        if (data.data.status == 200) {
            setUsertoken(data.data.users5[0].id);
        }
        if(localStorage.getItem('g') != null){
            const post = {
                id: localStorage.getItem('g'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                setUsertoken(data.data.users5[0].id);
            }
    }
        } 
  }
  

  const { Option } = Select;
  const handleChange = (value) => {
    setAtributes(value);
    
    
  };

  


  const addpost = async () => {

    const form = new FormData();

    form.append('location', location);
    // form.append('images', files[0]);
    // form.append('image1', files[1]);
    // form.append('image2', files[2]);
    // form.append('image3', files[3]);
    // form.append('image4', files[4]);
    // form.append('image5', files[5]);
    // form.append('image6', files[6]);
    form.append('price', price);
    form.append('numbers', numbers);
    form.append('descriptions', descriptions);
    form.append('categories', categories);
    form.append('currency', currency);
    form.append('userid', usertoken);
    form.append('city', city);
    form.append('podcategories', parseInt(podcategories));
    form.append('models', models);
    form.append('buy_sell', buy_sell);
    form.append('rooms', rooms);
    form.append('custom_atribute', JSON.stringify(atributes));
    form.append('period', period);
    form.append('floor', floor);
    form.append('square', square);
    form.append('acres', acres);
    form.append('metro', metro);
    
    const data = await axios({
      method: 'post',
      data: form,
      url: API_URL + '/client1',
      headers: { "Content-Type": "multipart/form-data" }
    });
    if (data != null) {
      if (data.status == 200) {
        success2();
get_my_ad();
      } else {
        error2();
      }
    }
  }
  const [my_ad, setMy_ad] = useState(null);
  const  get_my_ad = async () => {
    const post = {
        id: usertoken,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/my_ad'
    });
    
    if (data.data.status == 200) {
        setMy_ad(data.data.my_ad[0].id);
        // 
        window.location.href='/upload_image/'+ data.data.my_ad[0].id
    }if(useruid != null){
      const post = {
        id: useruid,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/my_ad'
    });
    
    if (data.data.status == 200) {
        setMy_ad(data.data.my_ad[0].id);
        // 
        window.location.href='/upload_image/'+ data.data.my_ad[0].id
    }
    } else {
      warning2();
    } 
}
const warning2 = () => {
  messageApi.open({
    type: 'warning',
    content: 'Ошибка! Переавторизуйтесь!',
  });
}

  // const addpost = async () => {
  //   const form = new FormData();
  //   form.append('location', location);
  //   form.append('images', files[0]);
  //   form.append('image1', files[1]);
  //   form.append('image2', files[2]);
  //   form.append('image3', files[3]);
  //   form.append('image4', files[4]);
  //   form.append('image5', files[5]);
  //   form.append('image6', files[6]);
  //   form.append('price', price);
  //   form.append('numbers', numbers);
  //   form.append('descriptions', descriptions);
  //   form.append('categories', categories);
  //   form.append('recommend', recommend);
  //   form.append('currency', currency);
  //   form.append('userid', userid);
  //   form.append('city', city);
  //   form.append('podcategories', parseInt(podcategories));
  //   form.append('models', models);
  //   form.append('buy_sell', buy_sell);
  //   form.append('rooms', rooms);
  //   form.append('custom_atribute', JSON.stringify(atributes));
  //   form.append('period', period);
  //   form.append('floor', floor);
  //   form.append('square', square);
  //   form.append('acres', acres);
  //   form.append('metro', metro);
  //   form.append('categories2', categories2);
    
  //   const data = await axios({
  //     method: 'post',
  //     data: form,
  //     url: API_URL + '/client1',
  //     headers: { "Content-Type": "multipart/form-data" }
  //   });
  //   
  //   if (data != null) {
  //     if (data.data.status == 200) {
  //       
  //       success2();
  //       window.location.href="/userinfo";
  //     } else {
  //       error2();
  //     }
  //   }
  // }
  const [messageApi, contextHolder] = message.useMessage();
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const success2 = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
    setTimeout(success,3000)
  };
  const error2 = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
    setTimeout(warning,3000)
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Выбирайте категории или заполняйте поле!',
    });
}

  const fetchProducts2 = async () => {
    const post = {
      id: 0,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL + '/getcategory'
    });
    if (data.data.status == 200) {
      setProducts2(data.data.getcategory);
      filter();
      
    } else {
      
      
    }
  }
  const filter = () => {
    if (products > 0 || products != null) {
      const ad = products.filter(i => i.parent_id == 0);
      
      if (ad.length > 0 && ad[0].city != null) {
        
        return ad[0].city;
      } else {
        return 'error';
      }
    }
  }
  const fetchCategory = async () => {
    setCustom();
    const params = {
      id: 1
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setCustom(true);
      setCustom3(false);
      setCustom4(false);
      setSubcategories(data.data.getcategory);
    } else {
      setCustom(false);
    }
  }
  const fetchCurrency = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcurrency',
    })
    
    if (data.data.status == 200) {
      setGet_currency(data.data.getcurrency);
    } else {

    }
  }
  const fetchCategory2 = async (id) => {
    setCustom3(false);
    setCustom5(false);
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setCustom2(true);
      setCustom3(false);
      setSubcategories2(data.data.getcategory);
    } else {
      setCustom2(false);
    }
  }
  const fetchCategory5 = async (id) => {

    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setCustom3(true);
      setCustom4(false);
      setSubcategories5(data.data.getcategory);

    } else {
      setCustom3(false);
    }
  }
  // Площадь,срок 
  const fetchCategory6 = async (id) => {

    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setCustom5(true);
      setSubcategories6(data.data.getcategory);

    } else {
      // setCustom3(false);
    }
  }
  const fetchCategory7 = async (id) => {

    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubcategories7(data.data.getcategory);
    } else {

    }
  }
  // главная категория 
  const onChange = async (value) => {
    
    setCategories(value);
    fetchCategory(value);
    setSubcategories(null);
    setCustom(false);
    setCustom2(false);
    setCustom3(false);
    setCustom4(false);
    setCustom5(false);
    setCustom6(false);
    setCustom7(false);
    setCustom8(false);
    setCustom9(false);
  };
  // podcategories 
  const onChange2 = async (value) => {
    setCustom9(false);
    if (value == 25){
      window.location.href='/kvartiry/' + value;
    }
    if(value == 26 || value == 27){
      fetchCategory5(2500);
      fetchCategory7(33);
      setCustom6(true);
      setCustom9(true);
      setCustom8(true);
      setCustom7(true);
    }
    if(value == 30 ){
      setCustom9(true);
      setCustom7(false);
    }
    if(value == 28 || value == 29){
    setCustom8(true);
    setCustom9(true);
    setCustom6(false);
    setCustom7(false);
    }
    if(value == 27){
      setCustom8(false);
      setCustom9(false);
    }
    
    
    setPodcategories(value);
    fetchCategory2(value);
    
  };
  // arenda prodaja
  const onChange3 = async (value) => {
      setCustom8(true);
      setCustom5(true);
      
      
      setBuy_sell(value);
      setSubcategories3(null);
      fetchCategory6(36);
     
    if(value == 31){
      fetchCategory7(33);
      setCustom7(true);
      
    }
    if(value == 32){
      setCustom5(false);
      setCustom4(false);
    }
    if(value == 33){
      fetchCategory7(value);
      fetchCategory6(40);
      setCustom7(true);
      setCustom4(true);
    }
    if(value == 34){
      setCustom4(false);
      setCustom5(false);
    }
    if(value == 35){
      fetchCategory7(33);
      setCustom4(false);
      setCustom5(false);
      setCustom7(true);
    }
  };
  const onChange4 = async (value) => {
    setRooms(value);
    setCustom4(false);
    
    setSubcategories4(null);
  };
  const onChange5 = async (value) => {
    if(value == 110 || value == 111 || value == 112 || value == 113 ){
      setCategories2(value);
    }else{
      setSubcategories4(null);
      
      fetchCategory4(value);
      setPeriod(value);
    }
  };
  const onChange6 = async (value) => {
    setFloor(value);
    
    
  };

  
  const fetchCategory4 = async (id) => {
    const params = {
      id: id
    }
    
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setCustom4(true);
      setSubcategories4(data.data.getcategory);
    } else {
      setCustom4(false);
    }

  }


  const [products, setProducts] = useState(null);
  const fetchProducts = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getmetro'
    });
    if (data.data.status == 200) {
      setProducts(data.data.getmetro);
      
    } else {
      
    }
  }
  
  useEffect(() => {
    useridtoken();
    fetchProducts();
    fetchCategory();
    fetchCurrency();
    filter();
    fetchCategory5(2500);
    fetchCategory7(33);
    fetchCategory2(26);
    setCustom2(true);
    setCustom6(true);
    setCustom9(true);
    setCustom8(true);
    setCustom7(true);
  }, [])
  return (
    <>
    {contextHolder}
      <Nav />
      <div style={{marginTop:'50px'}} className="container ">
        <div class="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 card">
            <div class="row">
              <div className='col-md-12'>
                <h5>Разместите БЕСПЛАТНОЕ обьявление</h5>
              </div>
              <div className='col-md-12'>
                <div className="row">
                  <div className="col-md-3">

                  </div>
                </div >
              </div>

                  {custom ?
                    <>
                      <div className="col-md-3 mt-3">

                        {subCategories?.length > 0 ?
                          <>
                            {/* Подкатегории */}
                            <Select onChange={onChange2} defaultValue={subCategories[1].name} style={{ width: '100%' }}>
                              {
                                subCategories.map((products) =>
                                  <Option value={products.id}>{products.name}</Option>
                                )
                              } </Select>
                          </> : <></>
                        }



                      </div>
                    </>
                    : <></>
                  }
                  {custom2 ?
                    <>
                      <div className="col-md-3 mt-3">
                        {subCategories2?.length > 0 ?
                          <>
                            {/* купить продать снимать */}
                            <Select onChange={onChange3} defaultValue={subCategories2[0].name} style={{ width: '100%' }}>
                              {
                                subCategories2.map((products) =>
                                  <Option value={products.id}>{products.name}</Option>
                                )
                              }
                            </Select>
                          </> : <></>
                        }
                      </div>

                    </>
                    : <></>
                  }
                  {custom6 ?
  <>
    <div className="col-md-3 mt-3">
      {subCategories5?.length > 0 ?
        <>
          {/* komnat */}
          <Select onChange={onChange4} defaultValue={subCategories5[0].name} style={{ width: '100%' }}>
            {
              subCategories5.map((products) =>
                <Option value={products.id}>{products.name}</Option>
              )
            }

          </Select>
        </> : <></>
      }
    </div>
  </> :
  <></>
}
{custom7 ?
  <>
    <div className="col-md-3 mt-3">
      {subCategories7?.length > 0 ?
        <>
          {/* etaj */}
          <Select onChange={onChange6} defaultValue={subCategories7[0].name} style={{ width: '100%' }}>
            {
              subCategories7.map((products) =>
                <Option value={products.id}>{products.name}</Option>
              )
            }
          </Select>
        </>:<>
        </>
      }
    </div>
  </> :
  <></>
}
                {custom8 ? <>
                {/* ploshad */}
                <div className="col-md-3 mt-3">
                    <Input onChange={(e) => { setSquare(e.target.value) }} rows={1} placeholder="Площадь (м2)" />
                </div>
                      
                </>:
                <></>
                }
                {custom9 ? <>
                <div className="col-md-3 mt-3">
                        <Input onChange={(e) => { setAcres(e.target.value) }} rows={1} placeholder="Пл.(соток)" />
                      </div>
                </>:
                <></>
                }
                  {custom5 ?
                    <>
                      <div className="col-md-3 mt-3">
                        {subCategories6?.length > 0 ?
                          <>
                            {/* srok  */}
                            <Select onChange={onChange5} defaultValue={subCategories6[0].name} style={{ width: '100%' }}>
                              {
                                subCategories6.map((products) =>
                                  <Option value={products.id}>{products.name}</Option>
                                )
                              }
                            </Select>
                          </> : <></>
                        }
                      </div>
                    </> :
                    <></>
                  }
                  {custom4 ?
                    <>
                    
                      <div className="col-md-3 mt-3">
                        {subCategories4?.length > 0 ?
                          <>
                            {/* room */}
                            {/* <Select onChange={onChange5} defaultValue={subCategories4[0].name} style={{ width: '100%' }}> */}
                            <Select
                              mode="multiple"
                              style={{
                                width: '100%',
                              }}
                              defaultValue={subCategories4[0].name}
                              onChange={handleChange}
                              optionLabelProp="label"
                            >
                              {
                                subCategories4.map((products) =>
                                  <Option value={products.name} >

                                    {products.name}

                                  </Option>

                                )
                              }
                            </Select>
                            {/* </Select> */}
                          </> : <></>
                        }


                      </div>
                    </> :
                    <></>
                  }  
               
              <div className="col-md-auto mt-3">
              {products != null || products != undefined || products ?.length > 0 ?<>
                  <Select
                    onChange={setMetro}
                  showSearch
                  style={{
                  width: 250, 
                }}
                
                placeholder="Метро"
                optionFilterProp="children"
                > 
                <Option value={null}>Все</Option>
              {products.map((products) =>
                <Option
                filterOption={(input, products) => (products?.label ?? '').includes(input)}
                filterSort={(productsA, productsB) =>
                  (productsA?.label ?? '').toLowerCase().localeCompare((productsB?.label ?? '').toLowerCase())
                }
                value={products.id_sql}>{products.Station}
                {products.color != 1 ? <> <MediumCircleFilled style={{color : '#' + products.color, fontSize:'22px'}} /> </> :
                                     <>
                                     <img src={products.image} width='15px' height='15px' style={{borderRadius:'50%'}}></img>
                                    </>}
                </Option>
              )
              }
              </Select>
        </>
        :<>Loading</>
        }
              </div>
              <div className='col-md-3 mt-3'>
                <Input id="price" name="price" type="number" defaultValue={0} onChange={(e) => { setPrice(e.target.value) }} rows={1} placeholder="Цена" />
              </div>
              <div className='col-md-3 mt-3'>
                {get_currency != null || get_currency != undefined || get_currency?.length > 0 ?
                  <><Select onChange={setCurrency} defaultValue={get_currency[1].currency} style={{ width: '100%' }}>
                    {get_currency.map((products) =>
                      <Option value={products.currency}>{products.currency}</Option>
                    )
                    }
                  </Select>
                  </>
                  : <>Loading</>
                }
              </div>
              <div className='col-md-auto mt-2'>
              <div class="row">
									<div class="col-md-3  col-4">
                  <Select
                      onChange={onChange0}
                      defaultValue={'1'}
                      style={{
                        marginLeft: '17px',
                        width:'100%',
                        height:'40px'
                      }}
                    >
                      <Option value={'2'}><img width='40px' height='40px'  src={uploads+'flag_kg.png'}></img>+996</Option>
                      <Option value={'1'}><img width='40px' height='40px'  src={uploads+'flag_rus.jpg'}></img>+7</Option>
                      {/* <Option value={'3'}><img width='40px' height='40px'  src={uploads+'gmail.png'}></img>@gmail</Option> */}
                    </Select>
                  </div>
									<div class="col-md-8 col-8 ">
                    {country == '1' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+7 *** *** ** **"
                      slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    {country == '2' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+996 *** ** ** **"
                      slotProps={{ input: { component: TextMaskAdapter2 } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    
            </div>
                </div>
              </div>
              <h5>Описание</h5>
              <InputEmojiWithRef
                
              maxLength={300}
              onChange={setDescriptions}
              // onChange={(e) => { setDescriptions(e.target.value) }}
              placeholder="Описание"
              name="descriptions"
              id="descriptions"
                color="neutral"
                minRows={2}
                variant="outlined"
              />
              {/* <p><b>Загрузите фото</b>(до 7)</p>
              <div style={{height:'100px'}} className="col-md-12">
                  <DragAndDropUploader
                    className="mb-3 p-2"
                    style={{ width: '100%', height: '100px', }}
                    onChange={(file) => {
                      setFiles([...files, file]);
                    }}
                    onRemove={(f) => {
                      const index = files.indexOf(f);
                      if (index !== -1) {
                        const f = files.splice(index, 1);
                        setFiles(f);
                      }
                    }}
                  />
                </div> */}

              <div className='col-md-12 mt-5'>
                <button style={{float:'right'}} onClick={addpost} className='btn btn-primary'>
                  Опубликовать
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>
      <Footer />
        <div  className={footer_menu_class}>
 <Footer_menu />
</div>
            
    </>
  )
}
export default Doma;


