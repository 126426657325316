import Footer from './footer';
import Nav from './navbar';
import Post2 from './post2';
import axios from 'axios';
import { API_URL, uploads } from '../config/config';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { click } from '@testing-library/user-event/dist/click';
import { message, Modal, Skeleton, Spin } from 'antd';
import { ArrowLeftOutlined, InfoCircleOutlined, LeftCircleOutlined, LeftSquareOutlined, MenuFoldOutlined, PhoneOutlined, RollbackOutlined, SendOutlined } from '@ant-design/icons';
import Footer_menu from './footer_menu';
import moment from 'moment';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';

import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import MoreVert from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ListDivider from '@mui/joy/ListDivider';
import Input from '@mui/joy/Input';
import { Button } from '@mui/joy';
import Textarea from '@mui/joy/Textarea';
import Picker from 'emoji-picker-react';
import InputEmoji from 'react-input-emoji'
const Mainsms = () => {

    const params = useParams();
    const user_id_params = params.id;
    
    const [messageApi, contextHolder] = message.useMessage();
    // const user_id = localStorage.getItem('token');
    const ad_id = localStorage.getItem('ad_id');
    const [product, setProduct] = useState(null);
    const [product2, setProduct2] = useState(null);
    const [products3, setProducts3] = useState(null);
    const [products, setProducts] = useState(null);
    const [product_chat, setProduct_chat] = useState(null);
    const [message1, setMessage1] = useState(null);
    const [room_id, setRoom_id] = useState(localStorage.getItem('room'));
    const [chats, setChats] = useState([]);
    const [products1, setProducts1] = useState([]);
    const [products2, setProducts2] = useState([]);
    const [getroom, setGetroom] = useState(null);
    const [chat1, setChat1] = useState(false);
    const [chat_last, setChat_last] = useState(null);
    const [status, setStatus] = useState(false);
    const [product_cat, setProduct_cat] = useState(null);
    const [chats_mob, setChats_mob] = useState(true);
    const [chat_mob, setChat_mob] = useState(false);
    const [user2_id, setUser2_id] = useState(null);
    const [user1_id, setUser1_id] = useState(null);
    const [usertoken, setUsertoken] = useState(null);
    const [status_id, setStatus_id] = useState(null);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    
    const useridtoken = async () => {
        if(localStorage.getItem('token') != null){
            const post = {
                id: localStorage.getItem('token'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                // 
                zag();
                setTimeout(() => {
                    fetcchat_if2(data.data.users5[0].id);
                }, 3000);
                setUsertoken(data.data.users5[0].id);
                fetchProducts1();
                fetchProducts3();
                chat_last1();
                fetchProduct2();
                // user_status(data.data.users5[0].id);
                user_status_get(data.data.users5[0].id);
               
               
            }if(localStorage.getItem('g') != null){
                const post = {
                    id: localStorage.getItem('g'),
                };
                const data = await axios({
                    method: 'get',
                    params: post,
                    url: API_URL + '/users5'
                });
                
                if (data.data.status == 200) {
                    zag();
                    setTimeout(() => {
                        fetcchat_if2(data.data.users5[0].id);
                    }, 3000);
                    chat_last1();
                    fetchProduct2();
                    // user_status(data.data.users5[0].id);
                    user_status_get(data.data.users5[0].id);
                    setUsertoken(data.data.users5[0].id);
                    fetchProducts1();
                    fetchProducts3();
                }
        }
            } 
      }
      const fetchProducts = async (ad_id) => {
        const post = {
            id: ad_id,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/post'
        });
        
        if (data.data.status == 200) {
            setProducts(data.data.post);
            // fetchProduct(data.data.post[0].userid);
            // 
        } else {
            
            // 
        }

    }
    const fetchProduct = async (user) => {
        const post = {
            id: user,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users1'
        });
        
        if (data.data.status == 200) {
            // 
            setProduct(data.data.users1);
        } else {
            

        }
    }
    const sms = async (room) => {
        const params = {
            user_id: usertoken,
            room_id: room
        };
        const data = await axios({
            method: 'put',
            params: params,
            url: API_URL + '/chat'
        });
        
        if (data != null) {
            if (data.status == 200) {
                setStatus(true);
            } else {
                setStatus(false);
            }
        }
    }
    const [user_status_value, setUser_status_value] = useState(null);
    const user_status_get = async (usertoken)=>{
        const params = {
            user1_id:usertoken
        };
        const data = await axios({
            method:'get',
            params:params,
            url:API_URL+'/user_status'
        });
        
        if(data != null){
            if(data.data.status  == 200){
                setUser_status_value(data.data.user_status);
            }else{
                user_status(data.data.user_status[0].user_id);
            }
        }
      }

    const user_status = async (usertoken)=>{
        const params = {
            user1_id: usertoken
        };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/user_status'
        });
        
        if(data != null){
            if(data.data.status  == 200){
            // 
            }else{
                user_status_update(usertoken);
            }
        }
      }
      const getChat = async (room,ad_id,user2_id,user1_id) => {
    
        if(ad_id != null){
            fetchProducts(ad_id);
        }
        if(user1_id != null){
            setUser1_id(user1_id);
            fetchProduct(user1_id != usertoken ? user1_id : user2_id);
        }
        if(room != null){
            sms(room);
        }
        if(user2_id != null){
            setUser2_id(user2_id);
        }

        
            setChat1(false);
            setChats_mob(false);
            setChat_mob(true);

 
        const data = await axios({
            method: 'get',
            url: API_URL + '/chat_2',
            params: { 'room_id': room }
        });
        
        if (data != null) {
            if (data.status == 200) {
                zag();
                setGetroom(data.data.room_id);
                setRoom_id(room);
                    setChat1(true);

                
                // 
            } else {
                alert('Error');
            }
        }
    }

      const user_status_update = async (usertoken)=>{
        const params = {
            user1_id:usertoken
        };
        const data = await axios({
            method:'put',
            params:params,
            url:API_URL+'/user_status'
        });
        
        if(data != null){
            if(data.data.status  == 200){
            // 
            }else{
                // alert('Error')
            }
        }
      }

      const [chats_if, setChats_if] = useState(null);
      const fetcchat_if2 = async (id) => {
        const params = {
            user1_id: id,
            user2_id : id
        };
        
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/chats'
        });
        
        if (data.data.status == 200) {
            setChats_if(data.data.chats);
            if(data.data.chats[0].user1_id == user_id_params){
                fetcchat_if3(data.data.chats[0].user1_id);
                // alert(data.data.chats[0].user1_id)
            }
            if(data.data.chats[0].user2_id == user_id_params){
                // alert(data.data.chats[0].user2_id)
                fetcchat_if4(data.data.chats[0].user2_id);
                
            }
            // const time = () => {
            //     setProduct_chat(data.data.chat_if3.id);
            //     fetchProducts2(data.data.chat_if3.ad_id);
            //     setChats(data.data.chat_if3);
            //     
            // }
            // setTimeout(time, 500);
        } else {
            // 
            // 
            // window.location.href='/vhod'
        }
    }
    const fetcchat_if3 = async (id) => {
        const params = {
            user1_id: id,
            user2_id: id,
        };
        // 
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/chat_if3'
        });
        
        if (data.data.status == 200) {
            const time = () => {
                setProduct_chat(data.data.chat_if3.id);
                fetchProducts2(data.data.chat_if3.ad_id);
                setChats(data.data.chat_if3);
            }
            setTimeout(time, 1000);
        } else {
            // 
        }if(user_id_params == null){
                window.location.href='/vhod'
        }
    }
    const fetcchat_if4 = async (id) => {
        const params = {
            user2_id: id,
            user1_id : id,
        };
        // 
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/chat_if3'
        });
        if (data.data.status == 200) {
            const time = () => {
                setProduct_chat(data.data.chat_if3.id);
                fetchProducts2(data.data.chat_if3.ad_id);
                setChats(data.data.chat_if3);
                // 
            }
            setTimeout(time, 1000);
        } else {
        }if(user_id_params == null){
                window.location.href='/vhod'
        }
    }
    const fetchProducts3 = async () => {
        const data = await axios({
            method: 'get',
            url: API_URL + '/cat_get'
        });
        if (data.data.status == 200) {
            setProducts3(data.data.cat_get);
            // 
        } else {
            // 
            // 
        }
    }

    const fetchProducts2 = async (ad_id) => {

        const data = await axios({        
            method: 'get',
            url: API_URL + '/client3'
        });
        // 
        if (data.data.status == 200) {
            setProducts2(data.data.posts);
        } else {
            // 
            // 
        }
    }


    const fetchProducts1 = async () => {
        const data = await axios({
            method: 'get',
            url: API_URL + '/client1'
        });
        
        if (data.data.status == 200) {
            setProducts1(data.data.posts);
        } else {
            // 
        }
    }

    const Ad_category = (categories) => {
        
        if (products2.length > 0 || products2 != null) {
            const ad = products2.filter(i => i.id == categories);
            // 
            if (ad.length > 0) {
                const ad2 = products3.filter(i => i.id == ad[0].categories);
                if (ad2.length > 0) {
                    return ad2[0].name
                }
            } else {
                return '';
            }
        }
    }
    const Ad_image = (id) => {
        // 
        if (products2.length > 0 || products2 != null){
            const ad = products2.filter(i => i.id == id);
            // 
            if (ad.length > 0 && ad[0].images != null) {
                
                return <img style={{ width: '60px', height: '60px', borderRadius: '25%' }} src={uploads + ad[0].images} />;
            } else {
                return <img style={{ width: '50px', height: '50px', borderRadius: '20%' }} src={uploads + 'no_image.jpg'} />;
            }
        }
    }
    const Ad_user_image = (user2_id) => {
        // 
        if (product2.length > 0 || product2 != null) {
            const ad = product2.filter(i => i.id == user2_id);
            // 
            if (ad.length > 0 && ad[0].avatar != null) {
                // 
                return <a  >
                    {/* <img style={{ width: '50px', height: '50px', borderRadius: '50%' }} src={uploads + ad[0].avatar} /> */}
                    <Avatar  src={uploads + ad[0].avatar} />
                    </a>;
            } else {
                return  <a  >
                    {/* <img style={{ width: '50px', height: '50px', borderRadius: '50%' }} src={uploads + 'nouser.png'} /> */}
                    <Avatar src={uploads + 'user.jpg'} />
                    </a>;
            }
        }
    }
    const Ad_user_name = (user2_id) => {
        if (product2.length > 0 || product2 != null) {
            const ad = product2.filter(i => i.id == user2_id);
            if (ad.length > 0 && ad[0].name != null) {
                return <a  ><p>{ad[0].name}</p></a>;
            } else {
                return  <a  ><p>Имя не задано</p></a>;
            }
        }
    }
    const users_status = (user2_id) => {
        // 
        if (user_status_value.length > 0 || user_status_value != null) {
            const ad = user_status_value.filter(i => i.user_id == user2_id);
            
            if (ad.length > 0 && ad[0].date != null) {
                // 
                return <a  ><p>{moment.tz(ad[0].date, "Europe/Moscow").fromNow()}</p></a>;
            } else {
                return  <a  ><p>{'Недавно'}</p></a>;
            }
        }
    }
    const chat_last1 = async () => {
        const params = {
            user_id: user_id_params,
            user_id2: user_id_params,
        };
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/chat_last'
        });
        
        if (data != null) {
            if (data.data.status == 200) {
                setChat_last(data.data.chat_last);
            } else {
                
            }
        }
    }
    const Ad_chat_last = (id) => {
        // 
        if (chat_last.length > 0 || chat_last != null) {
            const ad = chat_last.filter(i => i.room_id == id);
            // 
            if (ad.length > 0 && ad[0].message != null) {
                // 
                return ad[0].message;
            } else {
                return 'error';
            }
        }
    }
   
    const fetchProduct2 = async () => {
        const data = await axios({
            method: 'get',
            url: API_URL + '/users_get'
        });
        // 
        if (data.data.status == 200) {
            setProduct2(data.data.users_get);
        } else {
            

        }

    }
    const chat_if = async (id) => {
        const params = {
            ad_id: id,
            user1_id: usertoken,
            user2_id: id,
        };
        // 
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/chat_if'
        });
        
        if (data.data.status == 200) {
            setRoom_id(data.data.chat_if[0].id);
            chat(data.data.chat_if[0].id);
            // 
        } else {
            
            // 
        }
    }
    const chat = async (id, user_id2) => {
        if(message1 != null){
            const params = {
                ad_id: id,
                user_id: user_id_params,
                message: message1,
                room_id: room_id,
                user_id2: user1_id != usertoken ? user1_id : user2_id
            };
            const data = await axios({
                method: 'post',
                params: params,
                url: API_URL + '/chat'
            });
            
             if (data != null) {
            if (data.status == 200) {
                localStorage.setItem('room', room_id);
                localStorage.setItem('ad_id', id);
                setMessage1('');
                chats_date(room_id);
                getChat(room_id, id);
                chat_last1();
                fetchProducts1();
                fetchProduct2();
                fetchProducts3();
            } else {
                alert('Error')
            }
        }
        } 
       
    }
    const chats_date = async (room_id)=>{
        const params = {
            room_id:room_id
        };
        const data = await axios({
            method:'put',
            params:params,
            url:API_URL+'/chats'
        });
        
        if(data != null){
            if(data.data.status  == 200){
                fetcchat_if2(usertoken);
            // 
            }else{
                alert('Error')
            }
        }
    }
   

   
   

    const back = () => {
        chat_last1();
        fetcchat_if2();
        fetchProducts1();
        fetchProduct2();
        fetchProducts3();
        chat_if();
        setChat_mob(false);
        setChats_mob(true);
    }
    const back2 = () => {
        window.location.href = '/'
    }
    function copyToClipboard() {
        
        const str = document.getElementById('item-to-copy').innerText;
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert(`Copied: ${str}`);
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [delete_id, setDelete_id] = useState(null);
    const showModal = (id) => {
        
        setDelete_id(id);
      setIsModalOpen(true);
    };
    const handleOk = () => {
        put_status2(delete_id);
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    }; 
    const put_status = async (room_id,user1_id) => {
        const params = {
            'room_id' : room_id,
            'status' : '2',
            'user_id' : user1_id,
            'user2_id' : null
          }
        const data = await axios({
          method: 'post',
          params: params,
          url: API_URL+'/status_chat',
        });
        
        if (data != null) {
          if (data.status == 200) {
            // 
            window.location.reload();
          } else {
            alert('Error');
          }
        }
      }

    const put_status2 = async (room_id) => {
        
        const params = {
            'room_id' : room_id,
            'user_id' : usertoken,
            'user2_id' : usertoken
          }
          
        const data = await axios({
          method: 'get',
          params: params,
          url: API_URL+'/status_chat',
        });
        
        if (data.data.status == 200) {
            setStatus_id(data.data.chats_user_id);
            put_status(room_id,data.data.chats_user_id[0].user1_id);
            // if(data.data.chats_user_id[0].user1_id == usertoken){
            //     put_status(room_id,data.data.chats_user_id[0].user2_id);
                
            // }
            // if(data.data.chats_user_id[0].user2_id == usertoken){
            //     put_status3(room_id,data.data.chats_user_id[0].user2_id);

            // }
            
            fetchProduct(data.data.post[0].userid);
            // 
        } else {
            
            // 
        }
      }
    
      const put_status3 = async (room_id,user2_id) => {

        const params = {
            'room_id' : room_id,
            'status' : '2',
            'user2_id' : user2_id,
            'user_id' : null
          }
        const data = await axios({
          method: 'post',
          params: params,
          url: API_URL+'/status_chat',
        });
        
        if (data != null) {
          if (data.status == 200) {
            // 
            window.location.reload();
          } else {
            alert('Error');
          }
        }
      }
      const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject);
      };

      const success = () => {
        messageApi.open({
          type: 'success',
          content: 'Сообщение отправлено!',
        });
      };
      const success2 = () => {
        messageApi.open({
          type: 'success',
          content: 'Успешно!',
        });
      };
      
      const zag = () => {
        messageApi.open({
          content:  <Spin /> ,
        });
      };
      
      const warning = () => {
        messageApi.open({
          type: 'warning',
          content: 'Авторизуйтесь!',
        });
      }


    useEffect(() => {
        useridtoken();
    }, [])
    return (
        <>
       
       {contextHolder}
          
            {chats_mob ?
                <>
                    <div style={{height:'500px'}} class="container bg-light d-md-none d-lg-none">
                        {/* mob_ver-400  */}
                        <div class="row ">
                        
                       
                                <div  class="col-md-12  ">
                                <h1 class="kortxt2">Чаты</h1>
                            </div>

                            <div  className='chatscroll' style={{overflow:'scroll',height:'100%',minHeight:'590px'}} class="col-12 col-md-4 list-group" id="list-tab" role="tablist">
                            <Button style={{position:'absolute',width:'100%',zIndex:'1'}} className='fixed-bottom' onClick={back2} type="primary">
                                        Назад
                                        </Button>
                                     {chats.length > 0 && products1.length > 0 ?
                                        <>
                                            {chat_last.length > 0 && chat_last.length > 0 ?
                                                <>
                                                    {chats.map((item) =>
                                                        <>
                                <Box onClick={() => getChat(item.id, item.ad_id, item.user2_id,item.user1_id)} sx={{ width:'100%' }}>

      <List
        aria-labelledby="ellipsis-list-demo"
        sx={{ '--ListItemDecorator-size': '56px' }}
      >
        <ListItem>
          <ListItemDecorator>
          {item.user1_id != user_id_params ? <>{Ad_user_image(item.user1_id)}</> : <>{Ad_user_image(item.user2_id)}</> }
          </ListItemDecorator>

          <ListItemContent>
            <Typography level="title-sm">{Ad_category(item.ad_id)}</Typography>
            <Typography level="body-sm" noWrap>
            {Ad_chat_last(item.id)}
            </Typography>
            
          </ListItemContent>
          {Ad_image(item.ad_id)}
          
        </ListItem>
      </List>
      
    </Box>
    <hr style={{margin:'0'}}></hr>
    </>
                                                )}
                                               
                                            </>
                                            :
                                            <>
                                                <Skeleton
                                                    avatar
                                                    paragraph={{
                                                        rows: 4,
                                                    }}
                                                />



                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <Skeleton
                                            active
                                            avatar
                                            paragraph={{
                                                rows: 2,
                                            }}
                                        />
                                        <Skeleton
                                            active
                                            avatar
                                            paragraph={{
                                                rows: 2,
                                            }}
                                        />
                                         <Skeleton
                                            active
                                            avatar
                                            paragraph={{
                                                rows: 2,
                                            }}
                                        />
                                        <Skeleton
                                            active
                                            avatar
                                            paragraph={{
                                                rows: 2,
                                            }}
                                        />


                                    </>
                                }    
                                            
                                    
                                <div>
                                </div>
                            </div>

                            <div class="col-8 d-none d-sm-block">
                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="list-home-list">

                                        <div className='row'>
                                            {/* {product != null || product != undefined || product?.length > 0 ?
                                                <>
                                                    <div className='col-md-1'>
                                                    <a href={'/user/' + product[0].uid}><img style={{ width: '50px', height: '50px', borderRadius: '50%' }} src={product[0].avatar != null ? uploads + product[0].avatar : uploads + 'user.jpg'}></img></a>
                                                    </div>
                                                    <div className='col-md-9 '>
                                                        <b className='ml-2'>{product[0].name}</b>
                                                        <p className='text-secondary ml-2'>{users_status(product[0].id )}</p>
                                                    </div>
                                                    <div className='col-md-1 text-center mt-2'>
                                                        <i style={{ fontSize: '22px' }} class="fa-solid fa-phone text-success"></i>
                                                    </div>
                                                    <div className='col-md-1 text-center mt-1'>
                                                        <i style={{ fontSize: '34px' }} class="fa-solid fa-ellipsis text-secondary"></i>
                                                    </div>
                                                    <div style={{ marginTop: '-15px' }} className='col-md-12'>
                                                        <hr></hr>
                                                    </div>
                                                    {products != null || products != undefined || products?.length > 0 ?
                                                        <>
                                                            <div className='col-md-1'>
                                                                <img style={{ width: '50px', height: '50px', borderRadius: '10px' }} src={uploads + products[0].images}></img>
                                                            </div>
                                                            <div className='col-md-8 '>
                                                                <p className='ml-1 mt-2 text-secondary clip'><b  >{products[0].descriptions}</b></p>
                                                            </div>
                                                            <div className='col-md-3 '>
                                                            {products[0].price != 0 ? <><p  style={{ fontSize: '16px', float: 'right' }} className='ml-1 mt-1 ' ><b  >{products[0].price} </b><sub> {products[0].currency}</sub></p></> : <><p  style={{ fontSize: '10px', float: 'right' }} className='ml-1 mt-1 '><b>Договорная</b></p></>}
                                                            </div>
                                                            <div style={{ marginTop: '-10px' }} className='col-md-12'>
                                                                <hr></hr>
                                                            </div>
                                                        </>
                                                        : <>
                                                         
                                                        </>
                                                    }
                                                    <div className='col-md-12 '>
                                                        <div className='row ' style={{ height: '320px', overflow: 'scroll' }} >
                                                            {chat1 ?
                                                                <>
                                                                    {getroom != null || getroom != undefined ?
                                                                        <>
                                                                            {getroom.map((item) => {
                                                                                return (
                                                                                    <>
                                                                                        {
                                                                                            item.user_id == user_id_params ?
                                                                                                <div className='col-md-12'>
                                                                                                    {item.status == 1 ? <>
                                                                                                        <i style={{ marginTop: '8%', color: 'blue', float: 'right' }} class="fa-solid fa-check-double"></i>

                                                                                                    </> :
                                                                                                        <>
                                                                                                            <i style={{ float: 'right', marginTop: '8%' }} class="fa-solid fa-check-double"></i>
                                                                                                        </>}
                                                                                                    <label style={{ height: '50px', float: 'right', overflow: 'hidden', backgroundColor: '#F0FFF0', padding: '5px' }} className=' card'>{item.message}<p style={{ float: 'right', fontSize: '12px', marginTop: '5px',color:'gray' }}>{moment.tz(item.date1, "Europe/Moscow").fromNow()}</p></label>
                                                                                                </div>
                                                                                                :
                                                                                                <div className='col-md-12'>
                                                                                                    <label style={{ height: '50px', float: 'left', overflow: 'hidden', backgroundColor: '#F0FFFF', padding: '5px' }} className='card'>{item.message}<p style={{ float: 'right', fontSize: '12px', marginTop: '5px',color:'gray' }}>{moment.tz(item.date1, "Europe/Moscow").fromNow()}</p></label>
                                                                                                </div>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }
                                                                            )
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <h1>????</h1>
                                                                        </>
                                                                    }
                                                                </>
                                                                : <>

                                                                </>
                                                            }

                                                        </div>

                                                    </div>
                                                </>
                                                : <><div className='row text-center'>
                                                    <div className='col-md-12 none-400 d-sm-none'><img style={{ width: '150px', height: '150px' }} src={uploads + 'no_data_found.png'}></img></div>
                                                    <div className='col-md-12 none-400 d-sm-none'><h3 style={{ width: '250px', marginLeft: '250px' }}><b>Выберите чат, чтобы начать разговор</b></h3></div>
                                                </div>

                                                </>
                                            } */}
                                            {getroom != null || getroom != undefined ?
                                                <>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-1'>
                                                                <i style={{ fontSize: '36px', borderRadius: '10px' }} class="fa-solid fa-camera"></i>

                                                            </div>
                                                            <div className='col-md-8'>
                                                            <Input
                                                            placeholder='Сообщение...'
                                                            value={message1}
                                                            onChange={(e) => { setMessage1(e.target.value) }}
                                                        startDecorator={<i style={{ fontSize: '36px', borderRadius: '10px' }} class="fa-solid fa-camera"></i>}
                                                        endDecorator={
                                                            <>{products != null || products != undefined || products?.length > 0 ?
                                                                <>
                                                                    {chats.length > 0 && products1.length > 0 ?
                                                                        <>
                                                                            <Button onClick={() => chat(products[0].id, products[0].userid)}>Отправить</Button>
                                                                        </>
                                                                        : <></>
                                                                    }
                                                                </>
                                                                : <>
                                                                </>
                                                            }</>
                                                        }
                                                        />
                                                                {/* <input onChange={(e) => { setMessage(e.target.value) }} placeholder='Сообщение...' className='form-control'></input> */}
                                                            </div>
                                                            {/* {products != null || products != undefined || products?.length > 0 ?
                                                                <>
                                                                    {chats.length > 0 && products1.length > 0 ?
                                                                        <>
                                                                            <div className='col-md-2'>
                                                                                <button onClick={() => chat(products[0].id, products[0].userid)} className='btn btn-success'>Отправить</button>
                                                                            </div>

                                                                        </>
                                                                        : <></>
                                                                    }
                                                                </>
                                                                : <>
                                                                </>
                                                            } */}


                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>

                                                </>
                                            }
                                        </div>
                                    </div>
                                    {/* <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">2</div>
            <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">3</div>
            <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">4</div> */}
                                </div>
                            </div>
                        </div>





                    </div>
                </>
                : <>
                </>}

                
            

            <div  class=" bg-light d-none d-md-block">
           
                            <Nav />
                    
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="kortxt2">Чаты</h1>
                    </div>
                    
                    <div className='none-400' style={{ height: '500px', overflow: 'scroll' }} class="col-12 col-md-4 list-group" id="list-tab" role="tablist">
                    {chats.length > 0 && products1.length > 0 ?
            <>
             {chat_last.length > 0 ?
            <>
                {chats.map((item) =>
                    <>
    <Box className='hover-effect-btn'  sx={{ width:'100%' }}>
      <Typography
      onClick={() => getChat(item.id, item.ad_id,item.user2_id,item.user1_id)}
        id="ellipsis-list-demo"
        level="body-xs"
        textTransform="uppercase"
        sx={{ letterSpacing: '0.15rem' }}
      >
      </Typography>
      <List
        aria-labelledby="ellipsis-list-demo"
        sx={{ '--ListItemDecorator-size': '56px' }}
      >
        <ListItem>
          <ListItemDecorator onClick={() => getChat(item.id, item.ad_id,item.user2_id,item.user1_id)}>
             {item.user1_id != user_id_params ? <>{Ad_user_image(item.user1_id)}</> : <>{Ad_user_image(item.user2_id)}</> }
          </ListItemDecorator>
          <ListItemContent onClick={() => getChat(item.id, item.ad_id,item.user2_id,item.user1_id)}>
            <Typography level="title-sm">{Ad_category(item.ad_id)}</Typography>
            <Typography level="body-sm" noWrap>
            {Ad_chat_last(item.id)}
            </Typography>
          </ListItemContent >
          {Ad_image(item.ad_id)}
          <div style={{width:'40px'}} onClick={() => showModal(item.id)} class="button "><a><button style={{width:'40px',height:'40px',borderRadius:'50%'}} type="button" class="btn btn-outline-danger "><i class="fa-solid fa-trash "></i></button></a></div>
        </ListItem>

      </List>
    </Box> <hr style={{padding:'0',margin:'0'}}></hr> </>
                )}
            </>
            : <></>
        }
        </>
            : <></>
        }
        
        <Modal title="Удаление" open={isModalOpen} onOk={handleOk} cancelText='Нет' okText='Да' onCancel={handleCancel}>
        <p>Вы действительно хотите удалить этот чат ?</p>
      </Modal>
                        {/* {chats.length > 0 && products1.length > 0 ?
            <>
             {chat_last.length > 0 && chat_last.length > 0 ?
            <>
                {chats.map((item) =>
                    <>
                        <div  className='row mt-4' class="row hover-effect-btn" >
                            <div onClick={() => getChat(item.id, item.ad_id,item.user2_id,item.user1_id)} className='col-md-2 col-2'>
                            {item.user1_id != user_id_params ? <>{Ad_user_image(item.user1_id)}</> : <>{Ad_user_image(item.user2_id)}</> }
                            </div>
                            <div onClick={() => getChat(item.id, item.ad_id,item.user2_id,item.user1_id)} className='col-md-6 col-7'>
                                <b className='ml-3 txt-caategoryname-400'>{Ad_category(item.ad_id)}</b>
                                <p style={{width:'95%',overflow:'hidden'}} className='ml-3 text-secondary txt-400'>{Ad_chat_last(item.id)}</p>
                            </div>
                            <div onClick={() => getChat(item.id, item.ad_id,item.user2_id,item.user1_id)} className='col-md-3 col-3'>
                                {Ad_image(item.ad_id)}
                            </div>
                            <div onClick={() => showModal(item.id)} class="button col-md-1"><a><button style={{width:'40px',height:'40px',borderRadius:'50%'}} type="button" class="btn btn-outline-danger "><i class="fa-solid fa-trash "></i></button></a></div>
                            
                            <hr></hr>
                            
                        </div>
                        
      <Modal title="Удаление" open={isModalOpen} onOk={handleOk} cancelText='Нет' okText='Да' onCancel={handleCancel}>
        <p>Вы действительно хотите удалить этот чат ?</p>
      </Modal>
                    </>
                )}
            </>
            : <></>
        }
        </>
            : <></>
        } */}
        
                        <div>
                        </div>
                    </div>

                    <div class="col-8 d-none d-md-block">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="list-home-list">

                                <div className='row'>
                                    {/* {product != null || product != undefined || product?.length > 0 ?
                                        <>
                                            <div className='col-md-1'>
                                               <a href={'/user/' + product[0].id}><img style={{ width: '50px', height: '50px', borderRadius: '50%',}} src={product[0].avatar != null ? uploads + product[0].avatar : uploads + 'user.jpg'}></img></a>
                                            </div>
                                            <div style={{marginTop:'-20px'}} className='col-md-9 '>
                                                <b className='ml-2'>{Ad_user_name(product[0].id )}</b>
                                                <p className='text-secondary ml-2'>{users_status(product[0].id )}</p>
                                            </div>
                                            <div className='col-md-1 text-center mt-2'>
                                               <a href={'tel:'+product[0].numbers} id="item-to-copy" defaultValue={product[0].numbers} value={product[0].numbers}><i style={{ fontSize: '22px' }} class="fa-solid fa-phone text-success"></i></a> 
                                            </div>
                                            <div className='col-md-1 text-center mt-1'>
                                                <i style={{ fontSize: '34px' }} class="fa-solid fa-ellipsis text-secondary"></i>
                                            </div>
                                            <div style={{ marginTop: '-15px' }} className='col-md-12'>
                                                <hr></hr>
                                            </div>
                                            {products != null || products != undefined || products?.length > 0 ?
                                                <>
                                                    <div className='col-md-1'>
                                                        <a href={'/post/'+ products[0].id}><img style={{ width: '50px', height: '50px', borderRadius: '10px' }} src={products[0].images!= null ? uploads + products[0].images: uploads + 'no_data_found.png'}></img></a>
                                                    </div>
                                                    <div className='col-md-9 '>
                                                        <p className=' mt-2 text-secondary clip'><b  >{products[0].descriptions}</b></p>
                                                    </div>
                                                    <div className='col-md-2 '>
                                                        {products[0].price != 0 ? <><p style={{ fontSize: '16px', float: 'right' }} className=' mt-1 '><b  >{products[0].price} </b><sub> {products[0].currency}</sub></p></> : <><p style={{ fontSize: '16px', float: 'right' }} className=' mt-1 '><b>Договорная</b></p></>}
                                                        
                                                    </div>
                                                    <div style={{ marginTop: '-10px' }} className='col-md-12'>
                                                        <hr></hr>
                                                    </div>
                                                </>
                                                : <>
                                                <div className='text-center'> 
                                                    <h1 style={{marginLeft:'0px',color:'red',backgroundColor:'burlywood',width:'100%'}}>Недоступно</h1>
                                                </div>
                                                    

                                                </>
                                            }
                                            <div className='col-md-12 '>
                                                <div className='row ' style={{ minHeight: '300px', overflow: 'scroll', maxHeight: '300px' }} >
                                                    {chat1 ?
                                                        <>
                                                            {getroom != null || getroom != undefined ?
                                                                <>
                                                                    {getroom.map((item) => {
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    item.user_id == user_id_params ?
                                                                                        <div className='col-md-12 mt-1'>
                                                                                            {item.status == 1 ? <>
                                                                                                <i style={{ marginTop: '8%', color: 'blue', float: 'right' }} class="fa-solid fa-check-double"></i>

                                                                                            </> :
                                                                                                <>
                                                                                                    <i style={{ float: 'right', marginTop: '8%', position: 'relative', marginTop: '3%' }} class="fa-solid fa-check-double"></i>
                                                                                                </>}
                                                                                            <label style={{  float: 'right', overflow: 'hidden', backgroundColor: '#F0FFF0', padding: '5px',maxWidth:'300px',minHeight:'50px' }} className=' card'>{item.message}<p style={{ float: 'right', fontSize: '12px', marginTop: '5px',color:'gray' }}>[{moment.tz(item.date1, "Europe/Moscow").fromNow()}]</p></label>
                                                                                        </div>
                                                                                        :
                                                                                        <div className='col-md-12'>


                                                                                            <label style={{ minHeight: '50px', float: 'left', overflow: 'hidden', backgroundColor: '#F0FFFF', padding: '5px' ,maxWidth:'300px'}} className='card'>{item.message}<p style={{ float: 'right', fontSize: '12px', marginTop: '5px',color:'gray' }}>[{moment.tz(item.date1, "Europe/Moscow").fromNow()}]</p></label>

                                                                                        </div>
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                    )
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <h1>error</h1>
                                                                </>
                                                            }
                                                        </>
                                                        : <>

                                                        </>
                                                    }

                                                </div>

                                            </div>


                                        </>
                                        : <><div className='row text-center'>
                                            <div className='col-md-12 none-400 d-sm-none'><img style={{ width: '150px', height: '150px',marginLeft:'300px' }} src={uploads + 'no_data_found.png'}></img></div>
                                            <div className='col-md-12 none-400 d-sm-none'><h3 style={{ width: '250px', marginLeft: '250px' }}><b>Выберите чат, чтобы начать разговор</b></h3></div>
                                        </div>

                                        </>
                                    } */}
                                    {getroom != null || getroom != undefined ?
                                        <>
                                            <div style={{bottom:'0px'}} className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-11'>
                                                    <Input
                                                    placeholder='Сообщение...'
                                                    onChange={(e) => { setMessage1(e.target.value) }}
                                                        value={message1}
                                                        startDecorator={<a href={'tel:'+product[0].numbers}><Button><PhoneOutlined /></Button></a>}
                                                        endDecorator={
                                                            <>{products != null || products != undefined || products?.length > 0 ?
                                                                <>
                                                                    {chats.length > 0 && products1.length > 0 ?
                                                                        <>
                                                                            <Button onClick={() => chat(products[0].id, products[0].userid)}><SendOutlined /></Button>
                                                                        </>
                                                                        : <></>
                                                                    }
                                                                </>
                                                                : <>
                                                                </>
                                                            }</>
                                                        }
                                                        />
                                                        </div>
                                                    
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>

                                        </>
                                    }
                                </div>
                            </div>
                     </div>
                    </div>
                </div>





            </div>


            {/* mobilnyi chats */}
            {chat_mob ? <>
                <div   className='row d-sm-none d-md-none '>
                    {product != null || product != undefined || product?.length > 0 ?
                        <>
                           
                            {getroom != null || getroom != undefined ?
                            <>
                            <div style={{marginTop:'10px',paddingBottom:'0px'}} className='col-2 text-center'>
                            {getroom[0].user_id  != user_id_params ? <><div className='ml-3'>{Ad_user_image(product[0].id)}</div></> : <><div className='ml-3'>{Ad_user_image(product[0].id)}</div></> }
                            {/* <a href={'users_ad/' + product[0].id}>{Ad_user_image(product[0].id)}</a> */}
                            {/* <a href={'/users_ad/' + product[0].id}><img style={{ width: '50px', height: '50px', borderRadius: '50%',border:'1px solid blue',marginTop:'5px' }} src={product[0].avatar != null ? <>{uploads + product[0].avatar}</> : <>{uploads +'nouser.png'}</>} ></img></a> */}
                                
                            </div>
                            <div style={{paddingBottom:'0px',marginTop:'-10px'}} className='col-8 '>
                            {getroom[0].user_id != user_id_params ? <> <b style={{marginTop:'-10px',paddingBottom:'0px'}} className='ml-3'>{Ad_user_name(product[0].id )}</b></> : <><b style={{marginTop:'-10px'}} className='ml-2'>{Ad_user_name(user2_id)}</b></> }
                                {/* <b style={{marginTop:'-10px'}} className='ml-2'>{Ad_user_name(product[0].id)}</b> */}
                                <p style={{marginTop:'-10px'}} className='text-secondary ml-2'>{users_status(product[0].id )}</p>
                            </div>
                            </> : <></>}
                           
                            
                            <div style={{paddingBottom:'0px'}} className='col-1 mt-2 ml-2 '>
                            <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
      >
        <MoreVert />
      </MenuButton>
      <Menu placement="bottom-end">
        
        
        <ListDivider />
        <MenuItem onClick={() => showModal(room_id)} variant="soft" color="danger">
          <ListItemDecorator sx={{ color: 'inherit' }}>
            <DeleteForever />
          </ListItemDecorator >
          Удалить чат
        </MenuItem>
      </Menu>
    </Dropdown>
                            </div>
                            {/*mobilnyi versia */}
                            <div style={{padding:'0px',marginTop:'-25px'}} className='col-md-12'>
                                <hr></hr>
                            </div>
                            {products != null || products != undefined || products?.length > 0 ?
                                <>
                                    <div className='col-auto ml-2 '>
                                        {/* {Ad_image([])} */}
                                        <a href={'/post/'+ products[0].id}><img style={{ width: '40px', height: '40px', borderRadius: '10px', marginLeft: '5px' }} src={products[0].images != null ? uploads + products[0].images : uploads + 'no_data_found.png'}></img></a>
                                    </div>
                                    <div className='col-8 '>
                                        {/* <label className='text-secondary clip'><b  >{products[0].descriptions}</b></label> */}
                                    {/* <label className='text-secondary clip'><b  >{products[0].descriptions}</b></label> */}
                                    <p className="destext " >{products[0].descriptions}</p>
                                    </div>
                                    <div className='col-2 '>
                                    {products[0].price != 0 ? <><p style={{ fontSize: '12px', marginLeft:'10px',marginTop:'-5px',height:'0px'}} className=''><b>{products[0].price} </b><sub style={{ float: 'right' }}>{products[0].currency}</sub></p></> : <><p style={{ fontSize: '10px', marginLeft:'10px',marginTop:'-5px',height:'0px'}} ><b>Договорная</b></p></>}
                                        
                                    </div>
                                    <div style={{ marginTop: '-10px' }} className='col-md-12'>
                                        <hr></hr>
                                    </div>
                                </>
                                : <>
                                      <div className='text-center'> 
                                                    <h1 style={{marginLeft:'0px',color:'red',backgroundColor:'burlywood',width:'100%'}}>Недоступно!</h1>
                                                </div>

                                </>
                            }



                            {/* <div className='col-md-1 text-center mt-2'>
                                                    <i style={{ fontSize: '22px' }} class="fa-solid fa-phone text-success"></i>
                                                </div>
                                                <div className='col-md-1 text-center mt-1'>
                                                    <i style={{ fontSize: '34px' }} class="fa-solid fa-ellipsis text-secondary"></i>
                                                </div> */}


                            <div className='col-md-12 '>
                                <div className='row ' style={{ minHeight: '440px', overflow: 'scroll',maxHeight:'440px' }} >
                                    {chat1 ?
                                        <>
                                            {getroom != null || getroom != undefined ?
                                                <>
                                                    {getroom.map((item) => {
                                                        return (
                                                            <>
                                                                {
                                                                    item.user_id == user_id_params ?
                                                                        <div className='col-md-12 '>
                                                                            {item.status == 1 ? <>
                                                                                <i style={{ marginTop: '12%', color: 'blue', float: 'right' }} class="fa-solid fa-check-double"></i>
                                                                            </> :
                                                                                <>
                                                                                    <i style={{ float: 'right', marginTop: '12%' }} class="fa-solid fa-check-double"></i>
                                                                                </>}
                                                                            <label style={{  float: 'right', overflow: 'hidden', backgroundColor: '#F0FFF0', padding: '5px',width:'70%'}} className=' card'>{item.message}<p style={{ float: 'right', fontSize: '12px', marginTop: '0px',color:'gray' }}>[{moment.tz(item.date1, "Europe/Moscow").fromNow()}]</p></label>
                                                                        </div>
                                                                        :
                                                                        <div className='col-md-12'>
                                                                            <label style={{  float: 'left', overflow: 'hidden', backgroundColor: '#F0FFFF', padding: '5px',width:'70%' }} className='card'>{item.message}<p style={{ float: 'right', fontSize: '12px', marginTop: '0px',color:'gray' }}>[{moment.tz(item.date1, "Europe/Moscow").fromNow()}]</p></label>
                                                                        </div>
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    )
                                                    }
                                                    
                                                </>
                                                :
                                                <>
                                                    <h1></h1>
                                                </>
                                            }
                                        </>
                                        : <>

                                        </>
                                    }

                                </div>

                            </div>


                        </>
                        : <><div className='row text-center none-400'>
                            <div className='col-md-12 none-400'><img style={{ width: '150px', height: '150px', }} src={uploads + 'no_data_found.png'}></img></div>
                            <div className='col-md-12 none-400'><h3 style={{ width: '250px', marginLeft: '250px' }}><b>Выберите чат, чтобы начать разговор</b></h3></div>
                        </div>

                        </>
                    }
                    {getroom != null || getroom != undefined ?
                        <>
                            <div style={{ marginTop: '20px', height: '0px', backgroundColor: 'black',left:'0',bottom:'0',width:'100%' }} className='col-md-12'>
                                <div style={{ marginTop: '0px',bottom:'0' }} className='row'>
                                    <Input
                                            
                                            value={message1}
                                            placeholder='Сообщение...'
                                            onChange={(e) => { setMessage1(e.target.value) }}
                                            startDecorator={<a href={'tel:'+product[0].numbers}><Button><PhoneOutlined /></Button></a>}
                                        endDecorator={
                                            <>
                                            
                                            {products != null || products != undefined || products?.length > 0 ?
                                                <>
                                                    {chats.length > 0 && products1.length > 0 ?
                                                        <>
                                                            <Button onClick={() => chat(products[0].id, products[0].userid)}><SendOutlined className='' /></Button>
                                                        </>
                                                        : <></>
                                                    }
                                                </>
                                                : <>
                                                </>
                                            }</>
                                        }
                                        
                                        />
                                        <Button onClick={back} type="primary" className='mt-1' block>
                                        Назад
                                        </Button>
                                    {/* <div style={{ padding: '0px' }} className='col-2 p-0'>
                                    <button style={{borderRadius:'50%',width:'50px',height:'50px',marginTop:'-5px',marginLeft:'15px'}} type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  "><i style={{ fontSize: '26px' }} class="fa-solid fa-camera"></i></button>
                                    </div>
                                    <div style={{ padding: '0px' }} className='col-8 p-0'>

                                        <textarea value={message} style={{ padding: '0px', maxHeight: '200px', overflow: 'scroll' }} onChange={(e) => { setMessage(e.target.value) }} placeholder='Сообщение...222' className='form-control'></textarea>
                                    </div>
                                    {products != null || products != undefined || products?.length > 0 ?
                                        <>
                                            {chats.length > 0 && products1.length > 0 ?
                                                <>

                                                    <div style={{ padding: '0px' }} className='col-2 p-0'>
                                                    <button onClick={() => chat(products[0].id, products[0].userid)} style={{borderRadius:'50%',width:'50px',height:'50px',marginTop:'-5px',marginLeft:'5px'}} type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800  "><i style={{fontSize:'24px'}} class="fa-solid fa-paper-plane"></i></button>
                                                    </div>

                                                </>
                                                : <></>
                                            }
                                        </>
                                        : <>
                                        </>
                                    } */}


                                </div>
                            </div>
                        </>
                        :
                        <>

                        </>
                    }
                </div>
            </> :
                <>

                </>}

            <div className='col-md-12 d-none d-md-block '>
                <Footer />
            </div>
            {/* <div className='d-sm-block d-lg-none d-xl-none'>
                <Footer_menu />
            </div> */}
        </>
    );
}


export default Mainsms;

// {chats.length > 0 && products1.length > 0 ?
//     <>
//         {chat_last.length > 0 && chat_last.length > 0 ?
//             <>
//                 {chats.map((item) =>
//                     <>
//                     <div  onClick={() => getChat(item.id, item.ad_id, item.user2_id,item.user1_id)} className='row mt-4' class="row" >
//                         <div className='col-md-2 col-2'>
//                         {item.user1_id != user_id_params ? <>{Ad_user_image(item.user1_id)}</> : <>{Ad_user_image(item.user2_id)}</> }
//                         {/* {item.user1_id != user_id_params ? <>{Ad_user_image(item.user1_id)}</> : <>{Ad_user_image(item.user2_id)}</> } */}
//                         </div>
//                         <div className='col-md-7 col-7'>
//                             <b className='ml-3 txt-caategoryname-400'>{Ad_category(item.ad_id)}{item.price}</b>
//                             <p  className='ml-3 text-secondary txt-400'>{Ad_chat_last(item.id)}</p>
//                         </div>
//                         <div className='col-md-3 col-3'>
//                         {Ad_image(item.ad_id)}
//                         </div>
//                         <hr></hr>
//                     </div>

//                 </>
//             )}
//         </>
//         :
//         <>
//             <Skeleton
//                 avatar
//                 paragraph={{
//                     rows: 4,
//                 }}
//             />



//         </>
//     }
// </>
// :
// <>
//     <Skeleton
//         active
//         avatar
//         paragraph={{
//             rows: 2,
//         }}
//     />
//     <Skeleton
//         active
//         avatar
//         paragraph={{
//             rows: 2,
//         }}
//     />
//      <Skeleton
//         active
//         avatar
//         paragraph={{
//             rows: 2,
//         }}
//     />
//     <Skeleton
//         active
//         avatar
//         paragraph={{
//             rows: 2,
//         }}
//     />


// </>
// }