import Footer from './footer';
import Nav from './navbar';
import Post2 from './post2';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Input, Select, Space, Spin, message } from 'antd';
import { Option } from 'antd/lib/mentions';
import { API_URL, footer_menu_class, uploads } from '../config/config';
import { AudioOutlined, MediumCircleFilled } from '@ant-design/icons';
import Footer_menu from './footer_menu';
const { Search } = Input;
const Result1 =()=>{
    const local = localStorage.getItem('text');
    const [messageApi, contextHolder] = message.useMessage();
    const [token_metro, setToken_metro] = useState(null);
    const [product, setProduct] = useState(null);
    const [products_metro, setProducts_metro] = useState(null);
    const [products_metro2, setProducts_metro2] = useState();
    const [metro, setMetro] = useState(token_metro);
    const [products,setProducts] = useState(null);
    const [category,setCategory] = useState(null);
    const [ad_id,setAd_id] = useState(null);
    const [text, setText] = useState(localStorage.getItem('text'));
    const [limit, setLimit] = useState(10);
   
    const getCategory = async () => {
      const data = await axios({
          method:'get',
          url:API_URL+'/getcategory_vse'
      });
      if(data.data.status == 200){
          setCategory(data.data.getcategory_vse);
          
          Category(data.data.getcategory_vse);
      }else{
          
      }
  }
  const Category = (id) => {
    
    if (id.length > 0 || id != null){
        const ad = id.filter(i => i.name == text);
        
        if (ad.length > 0 && ad[0].id != null) {
          setAd_id(ad[0].id);
            
            return  <b>{ad[0].id}</b>;
        } else {
          setAd_id(null);
            return 
        }
    }
  }
      const fetchProducts = async () => {
        const data = await axios({
            method:'get',
            url:API_URL+'/getmetro'
        });
        if(data.data.status == 200){
            setProducts(data.data.getmetro);
            
        }else{
            
        }
    }

    const fetchProducts_metro = async () => {
        const params = {
            id: text,
            limit: limit
        }
    const data = await axios({
        params : params,
        method: 'get',
        url: API_URL + '/getmetro2'
    });
    if (data.data.status == 200){
      setProducts_metro(data.data.getmetro2);
      setLimit(limit+10);
      
    } else {
      
    }
  }
    const filtered = async () => {
      localStorage.setItem('text', text);
        const params = {
            id: text,
            limit: limit
        }
        const data = await axios({
            params : params,
            method: 'get',
            url: API_URL + '/getmetro3'
        });
        if (data.data.status == 200){
          setProducts_metro2(data.data.getmetro3);
          
          Search1(data.data.getmetro3[0].id_sql);
        }if(text != null){
      const post = {
          text: text,
          metro: metro,
          limit: limit
      };
      const data = await axios({
          method: 'get',
          params: post,
          url: API_URL+'/search'
      });
      
      if (data.data.status == 200) {
          error();
          setProduct(data.data.post);
          setLimit(limit+10);
          
      } else {
          error2();
          
          
      }
        }
    }

  

    const Search1 = async (metro) => {
            const post = {
                text: text,
                metro: metro,
                limit: limit
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL+'/search'
            });
            
            
            if (data.data.status == 200) {
                setProduct(data.data.post);
                
            } else {
                
                

        }
            
        }
        const success = () => {
            messageApi.open({
              type: 'success',
              content: 'Успешно!',
            });
          };
          const success2 = () => {
            messageApi.open({
              type: 'success',
              content: 'Успешно!',
            });
          };
         
          const error = () => {
            messageApi.open({
              content:  <Spin /> ,
            });
            setTimeout(success,2000)
          };
          const error2 = () => {
            messageApi.open({
              content:  <Spin /> ,
            });
            setTimeout(warning,1000)
          };
        
          const warning = () => {
            messageApi.open({
              type: 'warning',
              content: 'Попробуйте использовать другие ключевые слова или параметры',
            });
        }
        const suffix = (
          <AudioOutlined
            style={{
              fontSize: 16,
              color: 'green',
            }}
          />
        
        );
        function handleChange(e) {
          setText(e.target.value);
          
          // filtered();
      }
      const onChange = (value) => {
        
        localStorage.setItem('token_metro', value);
        setToken_metro(value);
        setMetro(value);
        // Metro(value);
      };
        const Text = () => {
          filtered();
  }
useEffect(() => {
    getCategory();
    fetchProducts_metro();
    filtered();
    fetchProducts();
}, [])

    return(
        
<>
{contextHolder}
<Nav/>
<div class="container bg-light">
    <div class="row">
       
        <div className='row'>
            <div style={{marginTop:'2%'}} className='col-md-12'>
                {/* <Search1 />  */}
                {/* <Space  style={{width:'100%',}} direction="vertical">
    <Search
      id='search'
      placeholder={'Вы искали: '+text}
      enterButton="Поиск"
      size='large'
      defaultValue={text}
      onChange={(e) =>{setText(e.target.value)}}
      suffix={suffix}
      onSearch={Text}
    />
  </Space> */}
   <Space  style={{width:'100%',}} direction="vertical">
    <Search
      id='search'
      placeholder={text != null ? 'Вы искали:' + text: 'Вы искали:  '}
      enterButton="Поиск"
      size='large'
      defaultValue={text != null ? text : ''}
      onChange={(e) =>{setText(e.target.value)}}
      suffix={suffix}
      onSearch={Text}
    />
  </Space>
  {/* <div class="input-group mb-3">
  <input type="text" class="form-control" onInput={handleChange}  defaultValue={text} onSubmit={filtered}  placeholder={'Вы искали: ' + text} aria-label="Recipient's username" aria-describedby="button-addon2" />
  <div class="input-group-append">
    <button onClick={Text} class="btn btn-primary" type="button" id="button-addon2">Найти</button>
  </div>
</div> */}
            </div>
            <div className='col-md-12'>
            <h2 >Фильтры</h2>
            </div>
            <div className="col-md-auto mt-3 ">
              {products != null || products != undefined || products ?.length > 0 ?<>
                  <Select
                    onChange={setMetro}
                  showSearch
                  style={{
                  width: 250, 
                }}
                placeholder="Метро"
                optionFilterProp="children"
                > 
                <Option value={null}>Все</Option>
              {products.map((products) =>
                <Option
                filterOption={(input, products) => (products?.label ?? '').includes(input)}
                filterSort={(productsA, productsB) =>
                  (productsA?.label ?? '').toLowerCase().localeCompare((productsB?.label ?? '').toLowerCase())
                }
                value={products.id_sql}>{products.Station}
                {products.color != 1 ? <> <MediumCircleFilled style={{color : '#' + products.color, fontSize:'22px'}} /> </> :
                                     <>
                                     <img src={products.image} width='15px' height='15px' style={{borderRadius:'50%'}}></img>
                                    </>}
                </Option>
              )
              }
              </Select>
        </>
        :<>Loading</>
        }</div>
        <div  className="col-md-2 col-12 mt-2">
          <Button  style={{float:'right'}} onClick={filtered}>Фильтровать</Button>
      </div>
            <div className='col-md-9'>

            </div>
            
        </div>

        <div class="col-md-12">
            <h1 class="kortxt2">Мы нашли...</h1> 
        </div>
   <div className='row'>
  
   </div>
       
        {product != null || product != undefined || product?.length > 0 ?
    <>
    {product.map((item)=>
    <div class="col-6 col-md-3 mt-3">
        <Post2 product={item}/>
    </div>
    
    )
    }
    </>
    :<>Loading</>
}
<div className='col-md-12'>
    <Button onClick={filtered} type="primary" block>
      Показать Ещё
    </Button>
    </div>

    </div>
</div>
<Footer/>
<div  className={footer_menu_class}>
 <Footer_menu />
</div>
            
    </>
    );
    }
    
    
    export default Result1;