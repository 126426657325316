import {useState } from 'react';
import React from 'react';
import { Button,Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { footer_menu_class, uploads } from '../config/config';
import {useSelector } from 'react-redux';
import Footer_menu from './footer_menu';
const tab1 = <Button  type="dark " ><a style={{color:'black'}} >Сервис и поддержка</a> </Button>;
const tab2 = <Button  type="dark " ><a style={{color:'black'}} >О нас</a> </Button>; 
const tab3 = <Button  type="dark " ><a style={{color:'black'}} >Правила размещения</a> </Button>;
const tab4 = <Button  type="dark " ><a style={{color:'black'}} >Реклама</a> </Button>;
const tab5 = <Button  type="dark " ><a style={{color:'black'}} >Монитор</a> </Button>;
const tab6 = <Button  type="dark " ><a style={{color:'black'}} >На мобильных</a> </Button>;
const Info = ()=>{
    const { products2 } = useSelector((state) => state.products);
    const params = useParams();
    const [id, setId] = useState(params.id);

    return(
        <>
         <div className='container'>
          <div className='row'>
          <Tabs defaultActiveKey={id}>
          <Tabs.TabPane className='row' tab={tab1} key="1">
            <label style={{fontSize:'22px'}}> <a href='/'><b>Birimdik</b></a> - доска объявлений для комфортного выбора
          Birimdik онлайн доска объявлений, с помощью которой поиск работы, вариантов размещения и других услуг на территории России (Москва и Московская область) и Кыргызстана становится удобным. Наглядность отображения информации, структурированный каталог и огромное количество предложений не оставят без удачного выбора! Полезными и актуальными неизменно будут новости, которые отображены в отдельном разделе. 

 Категории портала охватывают востребованные услуги по Москве, что делает <a href='/'>Birimdik</a> незаменимой практически для любых случаев жизни. Среди наиболее популярных разделов остаются объявления относительно: аренды квартир или номеров в гостиницах; открытых вакансий и предложений на рынке труда; транспортного обеспечения, включая услуги такси или грузоперевозок любого типа; образования различных уровней; товаров: одежды, для детей, продуктов питания и других категорий; предоставления медицинских услуг. 

 Отдельно представлены предложения заведений сферы досуга: популярные рестораны, кафе и развлекательные центры ждут своих гостей. Большинство предложений дополнено фотографиями реальных объектов. Это делает объявления максимально наглядными и ясными. 

 Чтобы воспользоваться порталом <a href='/'>Birimdik</a> необходимо просто указать интересуемый город и выбрать соответствующую категорию товаров или услуг. Отыскать лучшее предложение в любом городе теперь просто!
          </label>
          <b style={{fontSize:'22px'}}>Техподдержка</b>
          <b className='text-center'>Уважаемые пользователи!</b>
          <label>Вы можете написать нам по e-mail: birimdik.site@gmail.com, по:</label>
          <label>вопросам, связанным с размещением объявления на сайте и другими техническими вопросами. Тема письма "в Службу поддержки". При обращении в Службу поддержки указывайте e-mail указанный при регистрации и логин, в противном случае оно рассматриваться не будет.
          <br></br>
          вопросам технической поддержки рекламодателей, партнеров.</label>
         </Tabs.TabPane>
         <Tabs.TabPane className='row' tab={tab2} key="2">
            <label style={{fontSize:'22px'}}> <a href='/'><b>О проекте Birimdik</b></a>  - универсальная площадка, созданная для Вас.Каждый день тысячи людей со всего мира пользуются нашим ресурсом, предлагая и получая услуги и товары не только на месте проживания, но и вне пределов своего географического положения, что гораздо облегчает процесс обоим сторонам.Каждое объявление включает в себя множество характеристик, поэтому созданы фильтры, которые позволят Вам найти именно то, что нужно всего за несколько кликов.</label>
            
         </Tabs.TabPane>
         <Tabs.TabPane className='row' tab={tab3} key="3">
            <label style={{fontSize:'22px'}}> <a href='/'><b>Правила размещения </b></a>  При регистрации на Birimdik  - Пользователь соглашается с настоящими правилами и несет полную ответственность за ее содержание и возможные последствия его публикации. Birimdik уважает ваше право и соблюдает конфиденциальность при заполнении, передачи и хранении ваших конфиденциальных сведений.</label>
            <label style={{fontSize:'22px'}}><b>Внимание! Нарушение правил публикации может привести к ограничению доступа на длительный срок.</b></label>
            <b style={{fontSize:'16px'}}>Общие условия</b>
            <label>- Содержание рекламных объявлений не должно противоречить Федеральному закону
«О рекламе» № 38-ФЗ от 13.03.2006 г.- Недобросовестная, недостоверная, неэтичная реклама недопустима.- Пользователь может иметь только одну учетную запись на сайте.- Запрещено размещение дублирующих объявлений или нескольких объявлений одного и того же товара/услуги с разных E-mail.- Запрещаются к размещению объявления, содержащие в себе призывы к насилию и противоправным действиям; дискриминацию по национальному, расовому, религиозному, половому и другим признакам; ненормативную лексику;оскорбительные высказывания различного характера; мошенничество и вымогательство.- Раздел должен максимально соответствовать тематике Вашего объявления. Размещение объявления в неверный раздел может привести перемещению в более подходящий раздел или вовсе к удалению.- К публикации принимаются объявления на: русском языке, кыргызском языке, транслите</label>
         <b style={{fontSize:'16px'}}>Правила пользования</b>
         <label>Для подачи объявления, необходимо зарегистрироваться. После авторизации Пользователю доступны Личный кабинет и опции:- Добавления нового объявления;- Удаления добавленного объявления;- Обновления добавленного объявления (Пользователь имеет возможность обновить свое объявление через 6 часов после размещения на Birimdik с помощью таймера);- Раздел «Избранное»- Каждое объявление перед публикацией проходит модерацию на Birimdik Ограничений по количеству не будет, если они будут разными по содержанию, а также соблюден временной интервал в 6 часов, для схожих объявлений.При добавлении дублирующего объявления до истечения 4 часов, имеющиеся объявления со схожим смыслом будут удалены. Для экономии времени и во избежание лишних кликов рекомендуется обновить объявление.Срок размещения объявления 20 дней.</label>
         <b>Фотографии:</b>
         <label>- Фотография должна максимально подходить к тексту и разделу объявления.</label>
         <b>Заголовок:</b>
         <label>- Заголовок объявления должен соответствовать его содержанию;- Объявления в заголовке не должно содержать номер телефона, E-mail, адрес сайта, информацию о цене товара/услуги, станцию метро (любую другую информацию о месте расположения);- В заголовке объявления не допускается использование верхнего регистра, за исключением первых заглавных букв и имен собственных (целиком в верхнем регистре могут быть написаны только аббревиатуры), слов - псевдографики, разделения букв пробелами.</label>
         <b>Текст объявления:</b>
         <label>- Текст объявления должен содержать максимально подробную информацию о товаре/услуге, которые Вы предлагаете/ищите;- В тексте объявления не допускается использование верхнего регистра, за исключением первых заглавных букв и имен собственных (целиком в верхнем регистре могут быть написаны только аббревиатуры), слов - псевдографики, разделения букв пробелами</label>
         <b>Аккаунты пользователей, нарушивших правила размещения, будут заблокированы на 48 часов, а объявления не будут опубликованы.</b>
         <label>В целях содействия в обработке Вашего запроса, пожалуйста, предоставьте Ваше полное имя и координаты на наш электронный адрес info@birimdik.kg Настоящие правила могут быть изменены, дополнены или исправлены и вступают в силу с момента публикации на сайте.</label>
         <b>Политика конфиденциальности</b>
         <label>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую сайт доска объявлений Birimdik , расположенный на доменном имени http://birimdik.kg , может получить о Пользователе во время использования сайта доски объявлений.</label>
         <b>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</b>
         <label>1.1 В настоящей Политике конфиденциальности используются следующие термины:

1.1.1. «Администрация сайта доски объявлений (далее – Администрация сайта)» – уполномоченные сотрудники на управления сайтом, действующие от имени Birimdik, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
<br></br>
1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
<br></br>
1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
<br></br>
1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
<br></br>
1.1.5. «Пользователь сайта доски объявлений (далее - Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее сайт доски объявлений.
<br></br>
1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
<br></br>
1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</label>
<b>2. ОБЩИЕ ПОЛОЖЕНИЯ</b>
<label>2.1. Использование Пользователем сайта доски объявлений означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
<br></br>
2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта доски объявлений.
<br></br>
2.3.Настоящая Политика конфиденциальности применяется только к сайту доска объявлений BIRIMDIK.KG Доска объявлений не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте доски объявлений.
<br></br>
2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта доски объявлений. </label>
         <b>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</b>
         <label>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта доски объявлений по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте доски объявлений, при верификации отдельных Пользователей, в целях улучшения качества оказываемых услуг и обеспечения безопасности сделок, совершаемых Пользователями с использованием http://BIRIMDIK.KG.
         <br></br>
3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на сайте доски объявлений Birimdik в разделе Личный кабинет и включают в себя следующую информацию:
<br></br>
3.2.1. фамилию, имя, отчество Пользователя;
<br></br>
3.2.2. контактный телефон Пользователя;
<br></br>
3.2.3. адрес электронной почты (e-mail);
<br></br>
3.2.5. место жительство Пользователя (город).
<br></br>
3.3. Информация о посетителях сайта (IP-адрес, имя домена, тип браузера и операционная система, дата и время посещения и т. п.) собирается и сохраняется в целях ведения статистики посещений. 
<br></br>
3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.</label>
         <b>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</b>
         <label>4.1. Персональные данные Пользователя Администрация сайта доски объявлений может использовать в целях:
         <br></br>
4.1.1. Идентификации Пользователя, зарегистрированного на сайте доски объявлений, для улучшения качества оказываемых услуг и обеспечения безопасности сделок, совершаемых Пользователями с использованием http://BIRIMDIK.KG.
<br></br>
4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам сайта доски объявлений.
<br></br>
4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта доски объявлений, оказания услуг, обработка запросов и заявок от Пользователя.
<br></br>
4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
<br></br>
4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
<br></br>
4.1.6. Осуществления рекламной деятельности с согласия Пользователя.
<br></br>
4.1.7. Предоставления доступа Пользователю на сайты или сервисы партнеров доски объявления с целью получения продуктов, обновлений и услуг.</label>
        <b>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</b>
        <label>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
        <br></br>
5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на сайте доски объявления BIRIMDIK.KG.
<br></br>
5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
<br></br>
5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.
<br></br>
5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</label>
         <b>6. ОБЯЗАТЕЛЬСТВА СТОРОН</b>
         <label>6.1. Пользователь обязан:

6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом доски объявлений.
<br></br>
6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.
<br></br>
6.2. Администрация сайта обязана:
<br></br>
6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
<br></br>
6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
<br></br>
6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
<br></br>
6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</label>
       <b>7. ОТВЕТСТВЕННОСТЬ СТОРОН</b>  
       <label>7.1. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:

7.2.1. Стала публичным достоянием до её утраты или разглашения.
<br></br>
7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
<br></br>
7.2.3. Была разглашена с согласия Пользователя.</label>
         <b>8. РАЗРЕШЕНИЕ СПОРОВ</b>
         <label>8.1. Все вопросы, касающиеся доступа пользователей к сайту и использования размещенной на нем информации, регулируются действующим законодательством Российской Федерации. Любые спорные вопросы, касающиеся использования сайта, должны рассматриваться в судебной инстанции соответствующей юрисдикции.

8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.</label>
        <b>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</b>
        <label>9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.

9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте доски объявлений, если иное не предусмотрено новой редакцией Политики конфиденциальности.</label>
        <b>Ваш BIRIMDIK.KG</b>
         </Tabs.TabPane>
         <Tabs.TabPane className='row' tab={tab4} key="4">
            <b style={{fontSize:'22px'}}>РЕКЛАМА</b>
            <label>По вопросам рекламы и любым коммерческим предложениям, пишите на birimdik.site@gmail.com с темой «Реклама».</label>
          <Tabs>
            <Tabs.TabPane className='row' tab={tab5} key="5">
              <img style={{width:'800px',height:'700px'}} src={uploads+'reklamy.png'}></img>
            </Tabs.TabPane>
            <Tabs.TabPane className='row' tab={tab6} key="6">
              <img style={{width:'400px',height:'400px'}} src={uploads+'rek_mob.png'}></img>
            </Tabs.TabPane>
          </Tabs>
         </Tabs.TabPane>
        </Tabs>
          </div>
         </div>

         <div  className={footer_menu_class}>
          <Footer_menu />
        </div>
    </>
    
    );
    }
    
    
    export default Info;