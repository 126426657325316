import Footer from './footer';
import Nav from './navbar';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, footer_menu_class, uploads } from '../config/config';
import Footer_menu from './footer_menu';
import { useDispatch, useSelector } from 'react-redux';
import { setLikes } from '../redux/actions/like_actions';
import React from 'react';
import { setProducts } from '../redux/actions/products_actions ';
import Post2_like from './post2_like';
const Korzina =()=>{
    const { products } = useSelector((state) => state.products);
    const dispatch = useDispatch();
    const [zag, setZag] = useState(false);
    const [usertoken, setUsertoken] = useState(null);
    const [logged, setLogged] = useState(false);
    const useridtoken = async () => {
        if(localStorage.getItem('token') != null){
            const post = {
                id: localStorage.getItem('token'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                setLogged(true);
                setUsertoken(data.data.users5[0].id);
                
                setTimeout(() => {
                    fetchProducts2(data.data.users5[0].id);
                    fetchProducts(data.data.users5[0].id);
                }, 500);
            }if(localStorage.getItem('g') != null){
                const post = {
                    id: localStorage.getItem('g'),
                };
                const data = await axios({
                    method: 'get',
                    params: post,
                    url: API_URL + '/users5'
                });
                
                if (data.data.status == 200) {
                    setLogged(true);
                    setUsertoken(data.data.users5[0].id);
                    setTimeout(() => {
                        fetchProducts2(data.data.users5[0].id);
                        fetchProducts(data.data.users5[0].id);
                    }, 500);
                }
        }
            } 
      }

    const fetchProducts = async (usertoken) => {
       const params={
        user_id : usertoken
        };
        const data = await axios({
            method:'get',
            params: params,
            url:API_URL+'/get_favorites'
        });
        if(data.data.status == 200){
            dispatch(setProducts(data.data.favorites));
            fetchProducts2(usertoken);
        }else{
        
        }
    }
    const fetchProducts2 = async (usertoken) => {
        if(usertoken != null){
            const post = {
                user_id: usertoken,
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL+'/get_favorites'
            });
            if (data.data.status == 200) {
                console.log('dispastch',data);
                dispatch(setLikes(data.data.favorites));
                setZag(true);
            } else {
                
                
            }
        }else{
            
            alert(usertoken);
        }
       
      }
      
    useEffect(()=>{
        useridtoken();
        
    },[])
    return(
<>
<Nav/>
<div class="container bg-light">

    <div class="row">
    <div class="col-md-12">
            <h1 class="kortxt2">Избранные</h1>
        </div>
   
        {zag != false && products?.length > 0   ?
    <>
    {products.map((item)=>
    <div class="col-6 col-md-3 mt-3">
        <Post2_like product={item}/>
    </div>
    
    )
    }
    </>
    :<> Вы еще не добавляли в избранное</>
}

    </div>
</div>
<Footer/>
<div className={footer_menu_class}>
<Footer_menu />
</div>
    </>
    );
    }
    
    
    export default Korzina;