import axios from 'axios';
import { useEffect, useState } from 'react';
import Footer from './footer';
import Nav from './navbar';
import React from 'react';
import { Button, Image, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { API_URL, footer_menu_class, uploads } from '../config/config';
import { useDispatch, useSelector } from 'react-redux';
import Footer_menu from './footer_menu';

const Users_ad = ()=>{
    
    const dispatch = useDispatch();
    const params = useParams();
    const [neaktiv, setNeaktiv] = useState(null);
    const [status2, setStatus2] = useState(null);
    const [products, setProducts] = useState(null);
    const [products2, setProducts2] = useState(null);
    const [product, setProduct] = useState(null);
    const [product_cat, setProduct_cat] = useState(null);
    const { likes } = useSelector((state) => state.likes);
    const [favorited, setFavorited] = useState(null);
    const [usertoken, setUsertoken] = useState(null);
    const useridtoken = async () => {
        if(localStorage.getItem('token') != null){
            const post = {
                id: localStorage.getItem('token'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            // 
            if (data.data.status == 200) {
                
                setUsertoken(data.data.users5[0].id);
                fetchProduct(data.data.users5[0].id);
                fetchProducts(data.data.users5[0].id);
                fetchProducts2(data.data.users5[0].id);
            }if(localStorage.getItem('g') != null){
                const post = {
                    id: localStorage.getItem('g'),
                };
                const data = await axios({
                    method: 'get',
                    params: post,
                    url: API_URL + '/users5'
                });
                // 
                if (data.data.status == 200) {
                    // 
                    setUsertoken(data.data.users5[0].id);
            fetchProduct(data.data.users5[0].id);
            fetchProducts(data.data.users5[0].id);
            fetchProducts2(data.data.users5[0].id);
                }
        }
            } 
      }
    const fetchProduct = async (user_id) => {
        const post = {
            id: user_id,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL+'/users1'
        });
        
        if (data.data.status == 200) {
            setProduct(data.data.users1);
        } else {
            

        }  
    }
    const fetchProduct_cat = async (categories) => {
        const post = {
            id: categories,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/id_category'
        });
        if (data.data.status == 200) {
            setProduct_cat(data.data.post);
            
        } else {
            
            
        }
    }

const  fetchProducts = async (user_id) => {
      const post = {
          id: user_id,
      };
      const data = await axios({
          method: 'get',
          params: post,
          url: API_URL+'/my_ad'
      });
      if (data.data.status == 200) {
          setProducts(data.data.my_ad);
          
      } else {
          
          
      }
      
  }
 

const fetchProducts2 = async (user_id) => {
  const post = {
      user_id: user_id,
  };
  const data = await axios({
      method: 'get',
      params: post,
      url: API_URL+'/get_favorites'
  });
  if (data.data.status == 200) {
      setProducts2(data.data.favorites);
      
  } else {
      
      
  }
}

const fetchNeaktiv = async (user_id) => {
    const post = {
        user_id: user_id,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/neaktiv'
    });
    if (data.data.status == 200) {
        setNeaktiv(data.data.neaktiv);
        
        
    } else {
        
        
    }
}
const checkLikes = (id) => {
    
    const check = likes.filter(i => i.ad_id == id);
    if (check.length > 0) {
        // window.location.href="/";
        setFavorited(true);
        return true;
    } else {
        // window.location.href="/";
        setFavorited(false);
        // window.location.href="/";
        return false;
    }
}
if (favorited == null) {
    if (products != null || products != undefined || products?.length > 0) {
        checkLikes(product.id);
    }
}

const delfavorites = async (ad_id)=>{

    const params = {
        ad_id:ad_id,
        user_id:usertoken,
        
    };
    

    const data = await axios({
        method:'delete',
        params:params,
        url:API_URL+'/delete_favorites'
    });

    
    if(data != null){
        if(data.status  == 200){
        
        // setFavorited(fal);
        fetchProducts2();
        Users_ad.reload();
        }else{
            alert('Error')
        }
    }
}
const addfavorites = async (ad_id) => {

    const params = {
        ad_id: ad_id,
        user_id: usertoken,
    };

    const data = await axios({
        method: 'post',
        params: params,
        url: API_URL+'/favorites'
    });

    
    if (data != null) {
        if (data.status == 200) {
            
            // setFavorited(true);
            checkLikes();
            Users_ad.reload();
            // window.location.href = '/'
        } else {
            alert('Error')
        }
    }
}

const iz_status2 = async (ad_id)=>{
    const params = {
        status:'2',
        ad_id:ad_id
    };
    const data = await axios({
        method:'post',
        params:params,
        url:API_URL+'/iz_status2'
    });
    
    if(data != null){
        if(data.status  == 200){
        
        fetchProducts();
        // Userinfo.reload();
        // window.location.href="userinfo";
        }else{
            alert('Error')
        }
    }
}

const iz_status1 = async (ad_id)=>{
    const params = {
        status:'1',
        ad_id:ad_id
    };
    const data = await axios({
        method:'post',
        params:params,
        url: API_URL+'/iz_status2'
    });
    
    if(data != null){
        if(data.status  == 200){
        
        fetchNeaktiv();
        // Userinfo.reload();
        }else{
            alert('Error')
        }
    }
}

    useEffect(() => {
        useridtoken();
        fetchProduct_cat();
    }, [])
   
    const tab3 = <Button onClick={fetchProducts}  type="dark " ><a style={{color:'black'}}>Обьявления</a> </Button>;
   
    const tab8 = <Button  type="dark "  onClick={fetchProducts}>   <a style={{color:'black'}} >Активные</a> </Button>;  
    const tab9 = <Button  onClick={fetchNeaktiv} type="dark " >   <a style={{color:'black'}} >Неактивные</a> </Button>;  
    return(
        <>
        <Nav/>
        {product != null || product != undefined || product?.length > 0 ?
    <>
        <div class="container card usercard"> 
        
            <div class="col-md-12 col-12"> 
                <div class="row mt-2">
                <div className='col-md-2 col-3'><img style={{width:'100px',height:'100px',borderRadius:'50%'}} src={ product[0].avatar!= null ? 'https://birimdik.kg/birimdik/uploads/' + product[0].avatar : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></div>
            <div className='col-md-auto '>
                <p>{product[0].name != null ? <>{product[0].name}</> : <>{'Имя не задано'}</>} | {product[0].surname != null ? <>{product[0].surname}</> : <>{'Фамилия не задано'}</>}</p>
                <p></p> 
                <p>ID: {product[0].id}</p>
                <p>{product[0].numbers}</p> 
            </div>
           <div className='col-md-3 '> 
                <p> {'E-mail:'} </p>
                <p>О вас или о ваших компаниях: </p>
            </div>
                <div className='col-md-12 col-12'>
                <Tabs defaultActiveKey="1">
                
    
    <Tabs.TabPane className='row' tab={tab3} key="3">
        
    <Tabs defaultActiveKey="3">
    
        <Tabs.TabPane className='row' tab={tab8} key="8">
                    {products != null || products != undefined || products?.length > 0 ?
                <>
                <h5>Активные</h5>
                {products.map((item)=>
            <div   class="col-6 col-md-3 mt-3 ">
            <div  class="obv card" >
<label className=' medname'  style={{fontSize:'16px',fontFamily:'revert-layer',marginLeft:'10px'}}>{item.categories}<i style={{float:'right',fontSize:'10px'}} class="fa-solid fa-eye mt-2"> : 150</i></label>

<div style={{padding:'5px',marginTop:'-10px'}} class="card-body medbody">

<div class="row ">

        <a href={"/post/"+ item.id}>
        <Image style={{maxHeight:'200px',minHeight:'200px'}}  class="cardimg" width='100%' src={item.images != null ? ('https://birimdik.kg/birimdik/uploads/'+ item.images) : ('https://birimdik.kg/birimdik/uploads/'+ 'no_image3.png')}/>
        </a>
  
    
    <div class="col-md-12">
        <div className="row">
        <div class="col-md-12">
            <label style={{marginTop:'0px'}} class="card-title  txt7">{item.price}</label>
            <label style={{marginTop:'0px',marginLeft:'10px',fontSize:'12px'}} class="card-title  ">{item.currency}</label>
        </div>
        <div class="col-md-12">
            <p className='destext2'>{item.descriptions}</p>
        </div>
        <div class="col-md-auto ">
            <p className='location_text'>{item.metro}</p>
        </div>
        <div class="col-md-1">
        </div>
        <div class="col-md-3 meddate">
<label style={{fontSize:'10px',float:'right',marginRight:'-15px'}} class="text-secondary meddate">{item.date1}</label>
        </div>
       
       {/* <div className='col-md-6 mt-2'>
        <Button  type="primary" ghost href={"/put_ad/"+ product.id}>Редактировать</Button>
       </div> */}
       <div className='col-md-6 mt-2'>
       {/* {status ?
      <>
        <Button style={{padding:'1px'}} danger onClick={iz_status2}>Деактивировать</Button>

      </>
      :<>
        <Button style={{padding:'1px'}} danger onClick={iz_status1}>Активировать</Button>

      </>
}         */}
        
       </div>
    
        </div>
        <div  className='col-md-6 '>
                                    {favorited ?
                                    <>
                                        <a> <label class="text-secondary postsms"> <i type="button" data-toggle="modal" data-target="#exampleModal" class="fa-solid fa-envelope  sms2"></i> <i onClick={() => delfavorites(item.id)} class=" fa-solid fa-heart   heart2"></i></label></a>
                                    </>
                                    : <>
                                        <a>  <label class="text-secondary postsms"> <i type="button" data-toggle="modal" data-target="#exampleModal" class="fa-solid fa-envelope  sms2"></i> <i onClick={() => addfavorites(item.id)} class=" fa-solid fa-heart   heart"></i></label></a>

                                    </>
                                    }
                                </div>
        
        
    </div>

</div>
</div>
</div>  
           
        </div>



                
                )
                }
                </>
                :<>Нет активных обьявлений...
                </>
            }

        </Tabs.TabPane>

        <Tabs.TabPane className='row' tab={tab9} key="9">
        {neaktiv != null || neaktiv != undefined || neaktiv?.length > 0 ?
                <>
                <h5>Неактивные</h5>
                {neaktiv.map((item)=>
                
                     <div   class="col-6 col-md-3 mt-3 ">
                    <div  class="obv card" >
<label className=' medname'  style={{fontSize:'16px',fontFamily:'revert-layer',marginLeft:'10px'}}>{item.categories}<i style={{float:'right',fontSize:'10px'}} class="fa-solid fa-eye mt-2"> : 150</i></label>

    <div style={{padding:'5px',marginTop:'-10px'}} class="card-body medbody">
        
        <div class="row ">

                <a href={"/post/"+ item.id}>
                <Image style={{maxHeight:'200px',minHeight:'200px'}}  class="cardimg" width='100%' src={item.images != null ? ('https://birimdik.kg/birimdik/uploads/'+ item.images) : ('https://birimdik.kg/birimdik/uploads/'+ 'no_image3.png')}/>
                </a>
          
            
            <div class="col-md-12">
                <div className="row">
                <div class="col-md-12">
                    <label style={{marginTop:'0px'}} class="card-title  txt7">{item.price}</label>
                    <label style={{marginTop:'0px',marginLeft:'10px',fontSize:'12px'}} class="card-title  ">{item.currency}</label>
                </div>
                <div class="col-md-12">
                    <p className='destext2'>{item.descriptions}</p>
                </div>
                <div class="col-md-auto ">
                    <p className='location_text'>{item.metro}</p>
                </div>
                <div class="col-md-1">
                </div>
                <div class="col-md-3 meddate">
      <label style={{fontSize:'10px',float:'right',marginRight:'-15px'}} class="text-secondary meddate">{item.date1}</label>
                </div>
                
               {/* <div className='col-md-6 mt-2'>
                <Button  type="primary" ghost href={"/put_ad/"+ product.id}>Редактировать</Button>
               </div> */}
               <div className='col-md-6 mt-2'>
               {/* {status ?
              <>
                <Button style={{padding:'1px'}} danger onClick={iz_status2}>Деактивировать</Button>

              </>
              :<>
                <Button style={{padding:'1px'}} danger onClick={iz_status1}>Активировать</Button>

              </>
}         */}
                
               </div>
            
                </div>
                
                
                
            </div>

        </div>
    </div>
</div>  
                   
                </div>
                
               
                
                )
                }
                </>
                :<>Нет деактивированных обьявлений...
                </>
            }
        </Tabs.TabPane>
        
    </Tabs>
    
    </Tabs.TabPane>

   

    

   

  </Tabs>
                </div>
                
                <div className='row'>
                  
                
                </div>
                

                </div>
                
                
            </div>
           
        </div></>
    :<>Loading</>
}
<div className='col-md-12 '>
<Footer/>
</div>
      <div  className={footer_menu_class}>
 <Footer_menu />
</div>
           
        
    </>
    
    );
    }
    
    
    export default Users_ad;