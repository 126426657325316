import axios from "axios";
import { useState } from "react";
import Footer from "./footer";
import Nav from "./navbar";
import {
    Button,
    Form,
    Input
  } from 'antd';
  import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
  import React from 'react';
import { auth } from "../firebase/firebase-config";
import { useEffect } from "react";
import firebase from "firebase";
import { API_URL, footer_menu_class, uploads } from '../config/config';
import Footer_menu from "./footer_menu";
import { gapi } from "gapi-script";
import { FormControl, Select } from "@mui/material";
import { Option } from "antd/lib/mentions";
import { IMaskInput } from "react-imask";
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const clientId = '806199593777-9nsuk0sv1rroo6tkrmr5641u7e3td4fp.apps.googleusercontent.com';
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const TextMaskAdapter = React.forwardRef(function TextMaskAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+7 000 000 00 00"
        definitions={{
          '#': /[1-9]/
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        // overwrite
      />
    );
  });
  const TextMaskAdapter2 = React.forwardRef(function TextMaskAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+996 000 00 00 00"
        definitions={{
          '#': /[1-9]/
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        // overwrite
      />
    );
  });
  const TextMaskAdapter3 = React.forwardRef(function TextMaskAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="####################"
        definitions={{
          '#': /[a-z,A-Z,0-9,(?=.*\&^%$#@!_+{}":?></|)]/
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        // overwrite
      />
    );
  });

const Newpassword = () =>{
    const [componentSize, setComponentSize] = useState('default');
    const onFormLayoutChange = ({ size }) => {
      setComponentSize(size);
    };
    const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

 
    

    const [login,setLogin]= useState(null);
    const [password, setPassword] = useState();
    const [avatar,setAvatar] = useState();
    const [numbers, setNumbers] = useState();
    const [code, setCode] = useState();
    const [code2, setCode2] = useState('+996');
    const [uid, setUid] = useState();
    const [userid, setUserid] = useState();
    const [uid2, setUid2] = useState(null);
    const [final,setFinal] = useState();
    const [name,setName] = useState(null);
    const [surname,setSurname] = useState(null);
    const [city,setCity] = useState(null);
    const [style1,setStyle1]=useState(false);
    const [style2,setStyle2]=useState(false);
    const [product,setProduct] = useState(null);
    const [product2,setProduct2] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    const [country, setCountry] = useState('1');
    const onChange = (value) => {
      setCountry(value);
      setNumbers(null);
    };
  
    const fetchProduct = async () => {
      const post = {
          id: numbers,
      };
      const data = await axios({
          method: 'get',
          params: post,
          url: API_URL + '/users2'
      });
      
      if (data.data.status == 200) {
          setUserid(data.data.users2[0].id);
          setProduct2(data.data.users2);
          setUid(data.data.users2[0].uid);
          localStorage.setItem('token2',data.data.users2[0].id);
            
            // register(data.data.users2[0].numbers);
            setStyle2(true)
      } else {
        error2();
      }
  }
  //   const register = async ()=>{
  //     if(uid != null){
  //       auth.signInWithPhoneNumber( code2+`${numbers}`, window.verify).then((result) => {
  //         
  //         setFinal(result);
  //         fetchStyle1();
  //         alert("Код выслан вам на CМС!");
  //       }).catch((error)=>{
  //         alert("такой номер не зарегистрирован!");
  //       })
  //     }
  // }
const register = async () => {
    auth.signInWithPhoneNumber( code2+`${numbers}`, window.verify).then((result) => {
      setStyle2(true);
      setStyle1(true);
      success();
      
      setFinal(result);
      // fetchStyle1();
    }).catch((error) => {
      
      if(error == 'Error: reCAPTCHA client element has been removed: 0'){
        error3();
      }
      
      // alert('Слишком много попыток, повторите позже', error);
    })
  }
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const error2 = () => {
    messageApi.open({
      type: 'error',
      content: 'Такой номер не зарегистрирован!',
    });
  };
  const error3 = () => {
    messageApi.open({
      type: 'error',
      content: <b>Слишком много попыток повторите позже!<br/>или напишите в службу поддержку</b>
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'This is a warning message',
    });
  };


    const fetchStyle1 = async()=>{
      setStyle1(true);
  }

  const confirmOtp = () =>{
        
        if(code === null || final === null)
        return;
          final.confirm(code).then((result) =>{
            setUid2(result);
            
            setProduct(result);
          //  
          //  
          //  
           localStorage.setItem('number',numbers);
           window.location.href='registr2/' + userid;

          }).catch((err) => {
            
          });
  }

    const reg = async ()=>{
      
      const form = new FormData();

      form.append('login', login);
      form.append('password',password);
      form.append('avatar',avatar);
      form.append('numbers',numbers);
      form.append('name',name);
      form.append('surname',surname);
      form.append('city',city);
      form.append('uid',uid);
      
      const data = await axios({
          method:'post',
          data:form,
          url: API_URL + '/registr',
          headers: { "Content-Type": "multipart/form-data" }
      });
        
        if(data != null){
            if(data.status  == 200){
            
            // window.location.href="userinfo";
            window.location.href='registr2/' + product[0].id;
            }else{
                alert('Такой номер существует')
            }
        }
    }
    useEffect(()=>{
      window.verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
      window.verify.render();
      function start() {
        gapi.client.init({
          clientId: clientId,
          scope: ""
        })
      };
      gapi.load('client:auth2', start);
    },[])
    return(
        <>
        {contextHolder}
        
			<div  className="container ">
      <Nav/>
                <div  class="row">
                    
<div className="col-md-4  col-4">

</div>

<div class="col-md-4 col-12 card mt-5">
    <div class="row ">
    <div className='col-md-12 col-12 mt-5'>
        <h2 style={{color:'#2d2d7f'}}>Восстановления пароля</h2>
        
    </div>        
<div className="col-md-4 col-12 text-center">
{/* <p className="mobavatar" >Аватар</p> */}
{/* <input className="setavatar"  type={'file'} name="avatar" onChange={(e)=>{setAvatar(e.target.files[0])}} /> */}

{/* <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{
            width: '100%',
          }}
        />
      ) : (
        uploadButton
      )}
    </Upload> */}
</div>
<div className="col-md-12 col-12">

  

    <div className="row ">
    <div className="col-md-2 col-2"></div>
    <div className="col-md-8 col-8">
    <div className="row">


  </div>
    {/* <Input onChange={(e) =>{setPassword(e.target.value)}} /> */}
    </div>

    <div className="col-md-12 col-12 text-center mt-3">
      <label>
      
      </label>
    </div>
    <div class="col-md-3  col-4">
                  <Select
                      onChange={onChange}
                      defaultValue={'1'}
                      style={{
                        marginLeft: '17px',
                        width:'100%',
                        height:'40px'
                      }}
                    >
                      <Option value={'2'}><img width='40px' height='40px'  src={uploads+'flag_kg.png'}></img>+996</Option>
                      <Option value={'1'}><img width='40px' height='40px'  src={uploads+'flag_rus.jpg'}></img>+7</Option>
                      <Option value={'3'}><img width='40px' height='40px'  src={uploads+'gmail.png'}></img>@gmail</Option>
                    </Select>
                  </div>
									<div class="col-md-8 col-8 ">
                    {country == '1' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+7 *** *** ** **"
                      slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    {country == '2' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+996 *** ** ** **"
                      slotProps={{ input: { component: TextMaskAdapter2 } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    {country == '3' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="********** @gmail.com"
                      slotProps={{ input: { component: TextMaskAdapter3 } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                  
										{/* <Input   onChange={(e) => { setNumbers(e.target.value) }} type="text" id="numbers"  placeholder="Номер телефона" class="form-control inp" name=""/> */}
								</div>
      <div className="col-md-2 col-2"></div>

      <div className="col-md-4 col-4"></div>

<div className="col-md-4 col-4 mt-3">
{style1 ?
    <>
      <Input type="number" placeholder="Код из СМС" onChange={(e) =>{setCode(e.target.value)}} />
      <div className="col-md-4"></div>
      <div className="col-md-4 col-12 mt-3">
      <Form.Item >
        <Button type="secondary" onClick={confirmOtp} >OK1</Button>
      </Form.Item>
      </div>
    </>
    : <></>
  }

  </div>
      <div className="col-md-12 col-12 text-center">
      <label>
      {/* Пароль:  */}
      </label>
    </div>

    <div className="col-md-1 col-1"></div>

    

    <div className="col-md-2 col-2"></div>

    </div>

</div>


{style2 ?
 <>
</>:<>
<div id="recaptcha-container"></div>
<div className="col-md-12 col-12 mt-3 text-center">
      <Form.Item >
        <Button type="primary" data-toggle="modal" data-target="#exampleModalCenter" style={{float:'center'}} onClick={fetchProduct} >OK2</Button>
      </Form.Item>
    </div>
</>}
    <div className="col-md-12 col-12">
      
    </div>
    
      

    <div className="col-md-6 col-6">

</div>
    </div>
</div>

<div style={{marginTop:'250px'}}>
<Footer/>
</div>

</div>
</div>

{/* modal  */}
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       Вам выслано SMS-code, проверьте почту 
      </div>
      <div class="modal-footer">
        <button onClick={register} type="button" data-dismiss="modal" class="btn btn-primary">Ок</button>
      </div>
    </div>
  </div>
</div>
  <div  className={footer_menu_class}>
 <Footer_menu />
</div>
         
        </>
    )
}
export default Newpassword;