import React,{ Component } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { uploads } from '../config/config';

const Category =({products})=>{
    
    return(
<>
{products != null || products != undefined || products?.length > 0 ?
    <>
    <div style={{marginLeft:'12px',}} className='medcategory '><a href={'/' + products.link }><img  style={{width:'70px',height:'70px',borderRadius:'50%'}} src={ uploads+products.image}></img></a>
        <a href={'/' + products.link } style={{color:'black',fontSize:'14px',fontFamily:'initial',marginLeft:'-25%'}} >{products.name}</a></div>
        </>
    :<>Loading</>
}
</>
    );
    }
    export default Category;