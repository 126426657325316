import axios from 'axios';
import { useEffect, useState } from 'react';
import Footer from './footer';
import Nav from './navbar';
import React from 'react';
import { Button,message, Spin, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Post_lk_ak from './post_lk_ak';
import { API_URL, footer_menu_class, uploads } from '../config/config';
import Footer_menu from './footer_menu';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const Userinfo = ()=>{

    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const { products2 } = useSelector((state) => state.products);
    const params = useParams();
    const [id, setId] = useState(params.id);
    const dispatch = useDispatch();
    const user_uid = localStorage.getItem('token');
    const [neaktiv, setNeaktiv] = useState(null);
    const [moderatsii, setModeratsii] = useState(null);
    const [products, setProducts] = useState(null);
    const [products3, setProducts3] = useState(null);
    const [product, setProduct] = useState(null);
    const [product_cat, setProduct_cat] = useState(null);
    const [usertoken, setUsertoken] = useState(null);
    const [user2_id, setUser2_id] = useState(null);
    const [ad_id, setAd_id] = useState(null);
    const [logged, setLogged] = useState(false);
     const [favorited, setFavorited] = useState(null)
  const useridtoken = async () => {
    if(localStorage.getItem('token') != null){
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        if (data.data.status == 200) {
            setUsertoken(data.data.users5[0].id);
            fetchProduct(data.data.users5[0].id);
            fetchProducts3(data.data.users5[0].id);
 
        }if(localStorage.getItem('g') != null){
            const post = {
                id: localStorage.getItem('g'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
  
                setUsertoken(data.data.users5[0].id);
                fetchProduct(data.data.users5[0].id);
                fetchProducts3(data.data.users5[0].id);
                
            }
    }
        } 
  }
    const fetchProduct = async (user_id) => {
        const post = {
            id: user_id,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL+'/users1'
        });
        // 
        if (data.data.status == 200) {
            setProduct(data.data.users1);
        } else {
            // 

        }  
    }
   
const  fetchProducts3 = async (id) => {
      const post = {
          id: id,
      };
      const data = await axios({
          method: 'get',
          params: post,
          url: API_URL+'/my_ad'
      });
      if (data.data.status == 200) {
          setProducts3(data.data.my_ad);
      } else {

      } 
  }
 

const fetchNeaktiv = async () => {
    const post = {
        user_id: usertoken,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/neaktiv'
    });
    if (data.data.status == 200) {
        setNeaktiv(data.data.neaktiv);
        setId(9)
        // 
    } else {
        // 
        // 
    }
}
const fetchModeratsii = async () => {
    const post = {
        user_id: usertoken,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/moderatsii'
    });

    if (data.data.status == 200) {
        setModeratsii(data.data.moderatsii);

    } else {

    }
}


const iz_status3 = async (ad_id)=>{
    const params = {
        status:'3',
        ad_id:ad_id
    };
    const data = await axios({
        method:'post',
        params:params,
        url:API_URL+'/iz_status3'
    });
    // 
    if(data != null){
        if(data.status  == 200){
        fetchNeaktiv();
        Userinfo.reload();
        }else{
            alert('Error')
        }
    }
}


const [ad_get_value, setAd_get_value] = useState(null);
const [del_ad_id, setDel_ad_id] = useState(null);
const [images, setImages] = useState(null);
const [image1, setImage1] = useState(null);
const [image2, setImage2] = useState(null);
const [image3, setImage3] = useState(null);
const [image4, setImage4] = useState(null);
const [image5, setImage5] = useState(null);
const [image6, setImage6] = useState(null);
const ad_get = async (ad_id)=>{
    const params = {
      id: ad_id,
    };
    const data = await axios({
      method: 'get',
      params: params,
      url: API_URL+'/ad_get',
    });
    if (data.data.status == 200) {
        setAd_get_value(data.data.ad_get);
        setDel_ad_id(data.data.ad_get[0].id);
        setImages(data.data.ad_get[0].images);
        setImage1(data.data.ad_get[0].image1);
        setImage2(data.data.ad_get[0].image2);
        setImage3(data.data.ad_get[0].image3);
        setImage4(data.data.ad_get[0].image4);
        setImage5(data.data.ad_get[0].image5);
        setImage6(data.data.ad_get[0].image6);
        
        del_image(data.data.ad_get[0].id,data.data.ad_get[0].images,data.data.ad_get[0].image1,data.data.ad_get[0].image2,data.data.ad_get[0].image3,data.data.ad_get[0].image4,data.data.ad_get[0].image5,data.data.ad_get[0].image6);
    } else {
        
        
    }
  }
  const del_image = async (del_ad_id,images,image1,image2,image3,image4,image5,image6)=>{
    if(del_ad_id != null){
        const params = {
            ad_id: del_ad_id,
            images: images,
            image1: image1,
            image2: image2,
            image3: image3,
            image4: image4,
            image5: image5,
            image6: image6,
          };
      const data = await axios({
          method:'post',
          params:params,
          url:API_URL+'/del_image',
      });
      
      if(data != null){
          if(data.data.status  == 200){
        del_ad(del_ad_id,usertoken);
          }else{
            del_ad(del_ad_id,usertoken);
          }
      }
    }else{

    }
      
  }
  const del_ad = async (del_ad_id,user_id)=>{
    if(del_ad_id != null){
        const params = {
            ad_id: del_ad_id,
            user_id: user_id
          };
      const data = await axios({
          method:'post',
          params:params,
          url:API_URL+'/del_ad',
      });
      
      if(data != null){
          if(data.data.status  == 200){
            fetchNeaktiv();
            fetchModeratsii();
            Userinfo.reload();
          }
      }
    }else{
    //   error();
    }
      
  }
const delete_ad = async (ad_id)=>{
    const post = {
        id: id,
      };
      const data = await axios({
        method: 'delete',
        params: post,
        url: API_URL+'/update1',
      });
      fetchNeaktiv();
   
}



const [products_metro, setProducts_metro] = useState();
const fetchProducts_metro2 = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getmetro'
});
if (data.data.status == 200){
  setProducts_metro(data.data.getmetro); 
} else { 
}
}
const [products_category, setProducts_category] = useState(null);
const fetchProducts_category = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/cat_get'
});
if (data.data.status == 200){
  setProducts_category(data.data.cat_get);
//   
} else {
//   
}
}
const [number, setNumber] = useState();

  

    const tab1 = <Button  type="dark ml-3" > <a style={{color:'black'}} >Профиль</a> </Button>;
    const tab2 = <Button href='/korzina'   type="dark " ><a style={{color:'black'}} >Избранные</a> </Button>;
    // fetchProducts3(usertoken)
    const tab3 = <Button type="dark " ><a style={{color:'black'}}>Мои обьявлении</a> </Button>;
    // const tab4 = <Button  type="dark " ><a style={{color:'black'}} >Покупки</a> </Button>;
    // const tab5 = <Button  type="dark " ><a style={{color:'black'}} >Отзывы и вопросы</a> </Button>;
    // const tab6 = <Button  type="dark " ><a style={{color:'black'}} href={'/mainsms/' + user_uid} >Сообщении</a> </Button>;
    // const tab7 = <Button  type="dark " ><a style={{color:'black'}} >Баланс</a> </Button>; 
    const tab8 = <Button  type="dark "  onClick={fetchProducts3(usertoken)}>   <a style={{color:'black'}} >Активные</a> </Button>;  
    const tab9 = <Button  onClick={fetchNeaktiv} type="dark " >   <a style={{color:'black'}} >Деактивированные</a> </Button>;  
    const tab10 = <Button  onClick={fetchModeratsii} type="dark " >   <a style={{color:'black'}} >На модерации</a> </Button>;  

    const [products_click, setProducts_click] = useState(null);
const fetchProducts_click = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getclick'
});
// 
if (data.data.status == 200){
  setProducts_click(data.data.getview);
//   
} else {
//   
}
}
const click = async (ad_id) => {
    if (ad_id != null) {
        const params = {
            ad_id: ad_id,
        };
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/click_post'
        });
        // 
        if (data.data.status == 200){
            add_click(ad_id);
            
        } else {
           new_click(ad_id);
        }
    } else {
    }
}
const add_click = async (ad_id) => {
    const params = {
        ad_id: ad_id
    };
    const data = await axios({
        method: 'put',
        params: params,
        url: API_URL + '/click_post'
    });
    if (data != null){
        if (data.status == 200){
            window.location.href='/post/'+ad_id
        } else {
        }
    }
}
const new_click = async (ad_id) => {
    const params = {
        ad_id: ad_id,
        click: 1
    };
    const data = await axios({
        method: 'post',
        params: params,
        url: API_URL + '/click_post'
    });
    if (data != null){
        if (data.status == 200){
            window.location.href='/post/'+ad_id
        } else {
        }
    }
    const iz_status = async (ad_id)=>{
        const params = {
            status:'2',
            ad_id:ad_id
        };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/iz_status3'
        });
        // 
        if(data != null){
            if(data.status  == 200){
            // 
            window.location.href='userinfo/'+ 2
            }else{
                alert('Error')
            }
        }
    }
}



const [product3, setProduct3] = useState(null);
const [product4, setProduct4] = useState(null);
const fetchProduct4 = async () => {
    const post = {
        id: product.userid,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL +'/users1'
    });
    // 
    if (data.data.status == 200) {
        setProduct4(data.data.users1);
        // 
    } else {
        
        
    }
}



const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Сообщение отправлено!',
    });
  };
  const success2 = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const success3 = () => {
    messageApi.open({
      type: 'success',
      content: 'Добавлено в избранное!',
    });
  };
  const success4 = () => {
    messageApi.open({
      type: 'success',
      content: 'Удалено из избранное!',
    });
  };
 
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Авторизуйтесь!',
    });
}
const renderMetroInfo = (filteredMetro) => {
    const metroColor = filteredMetro.color !== 1 ? `#${filteredMetro.color}` : 'gray'; // Используем цвет из filteredMetro.color
  
    return (
      <div className="flex items-center text-gray-600 text-sm">
        {filteredMetro.color !== 1 ? (
          <>
            <span className="mr-2 truncate text-xs">{filteredMetro.Station}</span>

            <div 
              className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full flex items-center justify-center text-xs font-bold"
              style={{ backgroundColor: metroColor, color: 'white' }} // Применяем цвет фона и белый цвет для буквы
            >
              M
            </div>
          </>
        ) : (
          <>
            <span className="mr-2 truncate">{filteredMetro.Station}</span>
            <img 
              src={filteredMetro.image} 
              alt="Metro" 
              className="w-5 h-5 rounded-full flex-shrink-0" 
            />
          </>
        )}
      </div>
    );
  };

    useEffect(() => {
        useridtoken();
        fetchProducts_click();
        fetchProducts_metro2();
        fetchProducts_category();
        fetchProduct4();
        fetchProducts3();
    }, [])
    const formatPrice = (price) => {
        // Проверка на число и форматирование с разделителем тысяч
        const priceNumber = parseFloat(price); // Преобразуем строку в число, если это необходимо
        if (isNaN(priceNumber)) {
          return '—'; // Если цена не число, возвращаем дефолтное значение
        }
        return priceNumber.toLocaleString('ru-RU'); // Используем 'ru-RU' для форматирования с точкой как разделителем
      };
      
      if (!product ) {
        return <div>Загрузка...</div>; // Покажите индикатор загрузки или текст
      }

      const click_href = (ad_id) => {
        navigate('/update_ad/'+ad_id);
        }
    return(
        <>
        {contextHolder}
        {product != null || product != undefined || product?.length > 0 ?
    <>
    <Nav/>
   
               
                <div className='col-md-12 col-12'>
                <Tabs defaultActiveKey={id}>
                
    <Tabs.TabPane  className='row' tab={tab1} key="1">
    <h5>Профиль</h5>
                <div style={{height:'100%'}} className='col-md-3 col-lg-5 col-12 card ml-3 mt-2'>
                <div className='row'>
                      <div className='col-md-3 col-3'><img style={{width:'100px',height:'100px',borderRadius:'50%'}} src={ product[0].avatar!= null ? uploads + product[0].avatar : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></div>
                        <div className='col-md-1 col-1'></div>
                      <div className='col-md-7 col-7 ml-3 mt-3'>
                        <p>{product[0].name}</p>
                        <b style={{color:'gold'}}>Подключить в бизнес аккаунт</b>
                        <p>ID: {product[0].id}</p>                        
                      </div>
                    
                    <div className='col-md-12 col-12'>
                        <Button href='put_user2'>Изменить данные</Button>
                    </div>
                    </div>
                </div>
                
                <div className='card ml-3 mt-3 col-lg-5'>
                    <div className='row'>
                        <div className='col-md-12  col-12'>
                        <p style={{color:'grey'}}>Логин</p>
                        <label className='card'>{product[0].login}</label>
                    </div>

                    <div className='col-md-12 col-12'>
                        <p style={{color:'grey'}}>Пароль</p>
                        <label className='card'>{product[0].password}</label>
                    </div>
                    </div>
                </div>

                <div className='card ml-3 mt-3 col-lg-5'>
                    <div className='row'>
                    <div className='col-md-12'>
                    <p style={{color:'grey'}}>Имя</p>
                    <label className='card'>{product[0].name}</label>
                  </div>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Фамилия</p>
                    <label className='card'>{product[0].surname}</label>
                  </div>
                    </div>
                </div>

                <div className='card ml-3 mt-3 col-lg-5'>
                    <div className='row'>
                    <div className='col-md-12'>
                    <p style={{color:'grey'}}>Номер телефона</p>
                    <label className='card'>{product[0].numbers}</label>
                  </div>

                  <div className='col-md-12'>
                    <p style={{color:'grey'}}>Город</p>
                    <label className='card'>{product[0].city}</label>
                  </div>
                    </div>
                </div>

                <div className='card ml-3 mt-3 col-lg-5'>
                    <div className='row'>
                    <div className='col-md-12'>
                    <p style={{color:'grey'}}>E-mail</p>
                    <label className='card'>Не указано</label>
                  </div>

                  {/* <div className='col-md-12'>
                    <p style={{color:'grey'}}>Uid</p>
                    <label style={{maxHeight:'40px',overflow:'scroll'}} className='card'>{product[0].uid}</label>
                  </div> */}
                    </div>
                </div>

    </Tabs.TabPane>
    <Tabs.TabPane className='row' tab={tab2} key="2">
   
    </Tabs.TabPane>
    <Tabs.TabPane className='row' tab={tab3} key="3">
        
    <Tabs defaultActiveKey="3">
       
        <Tabs.TabPane className='row' tab={tab8} key="8">
                    {products3 != null || products3 != undefined || products3?.length > 0 ?
                <>
                <h5>Активные</h5>
                {products3.map((product)=>
            <div   class=" col-6 col-md-2 mt-3 ">   
           
          
                <Post_lk_ak product={product}/>
            </div>
                )
                }
                </>
                :<>
                <div className='ml-5'><label className='text-center'>Нет активных обьявлений...</label></div>
                
                </>
            }

        </Tabs.TabPane>

        <Tabs.TabPane className='row' tab={tab9} key="9">
        { neaktiv?.length > 0 ?
                <>
                <h5>Деактивированные</h5>
                {neaktiv.map((product)=>
                <div className='col-6 col-md-2 '>
                  <div className='card mt-3 '>
                  <div 
        className="relative h-56 w-full overflow-hidden cursor-pointer" >
        {/* Знак категории */}
        {products_category && (
          <div className="absolute top-2 left-2 z-1">
            {products_category
              .filter((category) => category.id === product.categories)
              .map((filteredCategory) => (
                filteredCategory.name && (
                  <span
                    key={filteredCategory.id}
                    className="bg-green-600 text-white text-xs px-2 py-1 rounded"
                  >
                    {filteredCategory.name}
                  </span>
                )
              ))}
          </div>
        )}

        {/* Кнопка "Избранное" */}
        
{favorited ?
<>
  <button 
     className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
  <i  className={`fa-heart  fa-solid text-red-400 fa-regular text-xl`} />
  </button>
</>
: <>
   <button 
     className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
  <i  className={`fa-heart  fa-regular text-xl`} />
  </button>
</>
}



        {/* Изображение продукта */}
        <img
          onClick={() => click(product.id)}
          src={product.images ? uploads + product.images : uploads + 'no_image.jpg'}
          alt="Image"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Детали продукта */}
    <div className="flex flex-col flex-grow p-1 ">
      {/* Цена */}
      <div className="flex justify-between items-center mb-0 ">
        <h2 className="text-lg  font-bold text-black roboto mb-0">
          {product.price != 0 
            ? `${formatPrice(product.price)} ${product.currency}` 
            : 'Договорная'}
        </h2>
        {/* {products_click != null ? <>
        {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
      <a>
          {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'3%',fontSize:'10px',zIndex:'1',padding:'1px'}} type="button" classname="btn btn-dark">Посмотрели: {filteredView.click}</button></> :
            <>
          </>}
      </a>
      ))}
      </> : <>
      </>} */}
      </div>

        {/* Описание */}
        <p className="text-gray-700 text-sm mb-0 h-10 overflow-hidden line-clamp-2 font-roboto">
            {product.descriptions}
        </p>


        {/* Информация о метро */}
        <div className="flex justify-between items-center mb-3">
          <div className="flex-grow overflow-hidden">
            {products_metro &&
              products_metro
                .filter((metro) => metro.id_sql === product.metro)
                .map((filteredMetro) => (
                  <div key={filteredMetro.id_sql}>
                    {renderMetroInfo(filteredMetro)}
                  </div>
                ))}
          </div>
          
          <div className="flex flex-col sm:flex-row items-start sm:items-center">
  <span className="text-xs sm:text-sm md:text-base lg:text-lg text-gray-500 ml-2 flex-shrink-0 block sm:inline">
    {moment.tz(product.date1, 'Europe/Moscow').fromNow()}
  </span>
  {/* Добавьте другие элементы, которые нужно отобразить рядом */}
</div>


        </div>

  
        <div className="mt-auto flex justify-between items-center">
        <button 
       onClick={()=>ad_get(product.id)}
  className="flex items-center justify-center bg-red-600 text-white hover:bg-green-700 transition-colors duration-200 ease-in-out rounded-full py-2 px-6 font-semibold shadow-lg transform active:scale-95"
  aria-label="Удалить"
>
<i class="fa-solid fa-trash text-sm"></i>
</button>

          <button 
  onClick={()=>iz_status3(product.id)}
  className="flex items-center justify-center bg-green-600 text-white hover:bg-green-700 transition-colors duration-200 ease-in-out rounded-full py-2 px-6 font-semibold shadow-lg transform active:scale-95"
  aria-label="Активировать"
>
<i class="fa-solid fa-power-off text-sm"></i>
</button>



        </div>
      </div>
                  </div>
                
                </div>
                )}
                
                </>
                :<>
                 <div className='ml-5'><label className='text-center'>Нет деактивированных обьявлений...</label></div>
                </>
            }
        </Tabs.TabPane>

        <Tabs.TabPane className='row' tab={tab10} key="10">
            {moderatsii?.length > 0  ? <>
                <h5>На модерации</h5>
                {moderatsii.map((product)=>
                <div className='col-6 col-md-2 mt-3'>
                <div className='card '>
                <div 
        className="relative h-56 w-full overflow-hidden cursor-pointer"
        
      >
        {/* Знак категории */}
        {products_category && (
          <div className="absolute top-2 left-2 z-1">
            {products_category
              .filter((category) => category.id === product.categories)
              .map((filteredCategory) => (
                filteredCategory.name && (
                  <span
                    key={filteredCategory.id}
                    className="bg-green-600 text-white text-xs px-2 py-1 rounded"
                  >
                    {filteredCategory.name}
                  </span>
                )
              ))}
          </div>
        )}

        {/* Кнопка "Избранное" */}
        
{favorited ?
<>
  <button 
     className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
  <i  className={`fa-heart  fa-solid text-red-400 fa-regular text-xl`} />
  </button>
</>
: <>
   <button 
     className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
  <i  className={`fa-heart  fa-regular text-xl`} />
  </button>
</>
}



        {/* Изображение продукта */}
        <img
          onClick={() => click(product.id)}
          src={product.images ? uploads + product.images : uploads + 'no_image.jpg'}
          alt="Image"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Детали продукта */}
    <div className="flex flex-col flex-grow p-1 ">
      {/* Цена */}
      <div className="flex justify-between items-center mb-0 ">
        <h2 className="text-lg  font-bold text-black roboto mb-0">
          {product.price != 0 
            ? `${formatPrice(product.price)} ${product.currency}` 
            : 'Договорная'}
        </h2>
        {/* {products_click != null ? <>
        {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
      <a>
          {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'3%',fontSize:'10px',zIndex:'1',padding:'1px'}} type="button" classname="btn btn-dark">Посмотрели: {filteredView.click}</button></> :
            <>
          </>}
      </a>
      ))}
      </> : <>
      </>} */}
      </div>

        {/* Описание */}
        <p className="text-gray-700 text-sm mb-0 h-10 overflow-hidden line-clamp-2 font-roboto">
            {product.descriptions}
        </p>


        {/* Информация о метро */}
        <div className="flex justify-between items-center mb-3">
          <div className="flex-grow overflow-hidden">
            {products_metro &&
              products_metro
                .filter((metro) => metro.id_sql === product.metro)
                .map((filteredMetro) => (
                  <div key={filteredMetro.id_sql}>
                    {renderMetroInfo(filteredMetro)}
                  </div>
                ))}
          </div>
          
          <div className="flex flex-col sm:flex-row items-start sm:items-center">
  <span className="text-xs sm:text-sm md:text-base lg:text-lg text-gray-500 ml-2 flex-shrink-0 block sm:inline">
    {moment.tz(product.date1, 'Europe/Moscow').fromNow()}
  </span>
  {/* Добавьте другие элементы, которые нужно отобразить рядом */}
</div>
        </div>
        <div className="mt-auto flex justify-between items-center">
        <button 
       onClick={()=>ad_get(product.id)}
  className="flex items-center justify-center bg-red-600 text-white hover:bg-green-700 transition-colors duration-200 ease-in-out rounded-full py-2 px-6 text-xs font-semibold shadow-lg transform active:scale-95"
  aria-label="Удалить"
>
<i class="fa-solid fa-trash text-sm"></i>
</button>
          <button 
          onClick={()=>click_href(product.id)}
  className="flex items-center justify-center bg-blue-600 text-white hover:bg-green-700 transition-colors duration-200 ease-in-out rounded-full py-2 px-6 text-xs font-semibold shadow-lg transform active:scale-95"
  aria-label="Редактировать"
>
<i class="fa-solid fa-pencil text-sm"></i>
</button>



        </div>
      </div>
                </div>
                
                </div>
                )}
            </>:<></>}
        </Tabs.TabPane>
        
    </Tabs>
    
    </Tabs.TabPane>

    {/* <Tabs.TabPane className='row' tab={tab4} key="4">
      Content of Tab Pane 2
    </Tabs.TabPane> */}

    {/* <Tabs.TabPane className='row' tab={tab5} key="5">
      Content of Tab Pane 2
    </Tabs.TabPane> */}

    {/* <Tabs.TabPane className='row' tab={tab6} key="6">
    
    </Tabs.TabPane> */}

    {/* <Tabs.TabPane className='row' tab={tab7} key="7">
      <div className='container'>
        <div className='row'>
            <div className='col-md-12'>
                <h2>Кошелек</h2>
            </div>
            <div className='col-md-6'>
                <h5>Кошелек - ваш персональный баланс на Birimdik.ru Пополняйте ваш Кошелек любым удобным способом и платите за услуги, не выходя из дома!</h5>
            </div>

            <div className='col-md-12'>
                <label style={{fontSize:'18px'}}>Выберите сумму пополнения:</label>
            </div>

            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>3000 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>2000 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>1000 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>500 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>300 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>200 KGS</label>
            </div>
            <div className='col-md-12'>
              <input type='radio'></input> <label style={{fontSize:'18px'}}>150 KGS</label>
            </div>
            <div className='col-md-12'>
                <button className='btn btn-primary'>Пополнить</button>
            </div>
        </div>
      </div>
    </Tabs.TabPane> */}

  </Tabs>
                </div>
                
                <div className='row'>
                  
                
                </div>
                

        <div className='col-md-12 '>
<Footer/>
</div>
            <div  className={footer_menu_class}>
                <Footer_menu />
            </div>
     
        </>
    :<>
    <h1 className='text-center mt-5'>Загрузка...</h1>
    </>
    
}

        
    </>
    
    );
    }
    
    
    export default Userinfo;